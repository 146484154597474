body {
    /******** Common for all vendors only Start *******/
    --airdrop_bg_light_gray: #f2f5fa;
    --airdrop_bg_primary: #fff;
    --airdrop_bg_secondary: #e4f0ff;
    --airdrop_bg_dark_black: #fff;
    --airdrop_bg_light_purple: #f6efff;
    --airdrop_filter_primary: none;
    --airdrop_text-white: #fff;
    --airdrop_shadow: #9797974d;
    --airdrop_gradient_primary: linear-gradient(
        180deg,
        #f6efff 0%,
        rgba(255, 255, 255, 0.262917) 79.59%,
        rgba(255, 255, 255, 0) 101.19%
    );
    --airdrop_gradient_light_blue: linear-gradient(
        180deg,
        #f1f7ff 0%,
        rgba(255, 255, 255, 0.262917) 79.59%,
        rgba(255, 255, 255, 0) 101.19%
    );

    /******** Common for all vendors only End *******/

    /******** S4F Variables Start**************/
    --s4f_gradient_primary: linear-gradient(267deg, #59d2e3 3.13%, #7a14ef 33.87%, #a423cb 69.99%, #d635a0 100%);
    --s4f_gradient_secondary: linear-gradient(90deg, #59d2e3 3.13%, #7a14ef 33.87%, #a423cb 69.99%, #d635a0 100%);
    --s4f_gradient_third: linear-gradient(180deg, #a193ff 0%, #b92fff 62.5%, #dd0eff 100%);
    --s4f_gradient_four: linear-gradient(90deg, #59d2e3 3.13%, #7a14ef 33.87%, #a423cb 69.99%, #d635a0 100%);
    --s4f_gradient_five: linear-gradient(144.26deg, #607bff 7.91%, #ee00ff 89.11%);
    --s4f_bg_pink: #ffe4fb;
    /******** S4F Variables End **************/

    /******** ITB variable Start *******/
    --itb_text_primary: #2e71f0;

    /******** ITB variable End *******/

    /******** BPOF variable Start *******/
    --bpof_gradient_primary: linear-gradient(27deg, #0a1941 5.9%, #063c95 84.94%);
    --bpof_gradient_secondary: linear-gradient(211deg, #0a1941 5.9%, #063c95 84.94%);
    --bpof_gradient_third: radial-gradient(
        51.75% 87.05% at 49.8% -0.9%,
        #0056bf 0%,
        #02429f 13.5%,
        #043183 28.6%,
        #052671 41.7%,
        #05226b 51.3%,
        #072060 62%,
        #091d50 83.5%,
        #0a1c4a 100%
    );
    --bpof_text_gradient: linear-gradient(89.91deg, #0245a4 12.11%, #004aab 49.99%, #01206c 89.49%);
    --bpof_bg_primary: #e4f0ffab;

    /******** BPOF variable End *******/

    /******** blockGame Variables Start**************/
    --block_game_gradient_primary: linear-gradient(70deg, #004c5e 13.11%, #29005d 48.77%, #600d9f 86.43%);
    --block_game_gradient_secondary: linear-gradient(70deg, #600d9f 13.11%, #29005d 48.77%, #004c5e 86.43%);
    --block_game_gradient_third: #00737b;
    --block_game_gradient_four: linear-gradient(90deg, #59d2e3 3.13%, #7a14ef 33.87%, #a423cb 69.99%, #d635a0 100%);
    --block_game_gradient_five: linear-gradient(144.26deg, #607bff 7.91%, #ee00ff 89.11%);
    --block_game_bg_green: #00737b;
    --block_game_modal_bg: #f1f1f1;
    /******** blockGame Variables End **************/

    /******** bober Variables Start**************/
    --bober_primary: #653b20;
    --bober_gradient_primary: linear-gradient(266deg, #3a2b23 6.35%, #653b21 96.26%);
    --bober_gradient_secondary: linear-gradient(266deg, #653b21 6.35%, #3a2b23 96.26%);
    --bober_winner_list: #d9faf2;
    /******** bober Variables End **************/

    /******** ta-da Variables Start**************/
    --tada_primary: #d403fe;
    --taDa_gradient_primary: linear-gradient(90deg, rgba(127, 1, 251, 1) 0%, rgba(176, 1, 224, 1) 100%);
    --taDa_gradient_secondary: linear-gradient(90deg, rgba(176, 1, 224, 1) 0%, rgba(127, 1, 251, 1) 100%);
    --taDa_history_background: #f7f0ff;
    /******** ta-da Variables End **************/
}
body.darkTheme {
    /******** Common for all vendors only Start *******/
    --airdrop_bg_light_gray: #2e3035;
    --airdrop_bg_primary: #252525;
    --airdrop_bg_secondary: #424242;
    --airdrop_bg_dark_black: #1a1a1a;
    --airdrop_bg_light_purple: #212529;
    --airdrop_filter_primary: brightness(0.3);
    --airdrop_text-white: #000;
    --airdrop_gradient_primary: #1a1a1a;
    --airdrop_gradient_light_blue: #1a1a1a;
    /******** Common for all vendors only End *******/
    /******** S4F Variables Start **************/
    --s4f_gradient_secondary: #fff;
    --s4f_bg_pink: #424242;
    /******** S4F Variables End **************/
    /******** ITB variable Start *******/

    --itb_text_primary: #fff;
    /******** ITB variable End *******/

    /******** BPOF variable Start *******/
    --bpof_text_gradient: #fff;
    --bpof_bg_primary: #25282b;
    /******** BPOF variable End *******/

    /******** blockGame Variables Start**************/
    --block_game_modal_bg: #0e0e0e;
    /******** blockGame Variables End **************/

    /******** bober Variables Start **************/
    --bober_winner_list: #d9faf224;
    /******** bober Variables End **************/

    /******** ta-da Variables start **************/
    --taDa_history_background: #1a1a1a;
    /******** ta-da Variables End **************/
}
