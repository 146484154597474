$small: 576px;
$width767: 767px;
$medium: 768px;
$large: 992px;
$extraLarge: 1200px;
$extraExtraLarge: 1400px;
$extraLargeXL: 1920px;
$sm991: 991px;
$md1024: 1024px;
// extra small screen
$xs400: 400px;
$xs425: 425px;
$xs420: 420px;
$xs575: 575px;
$xs450: 450px;
$xs375: 375px;
$xs360: 360px;
$xs350: 350px;
$xs325: 325px;
$xs320: 320px;
$xs300: 300px;

$primaryGradient: linear-gradient(281deg, #8255ec 0%, #3d60fd 100%); // for nft

// @mixin dealayhalf {
//     transition: ease all 0.5s;
// }

// @mixin dealayone {
//     transition: ease all 0.5s;
// }
/************** Default button Mixin ************/
@mixin buttonWhite {
    display: inline-block;
    transition: all 0.3s;
    position: relative;
    background: transparent;
    overflow: hidden;
    z-index: 1;
    outline: none;
    @include font-600(0.875rem);
    height: 38px;
    box-shadow: none !important;
    padding: 7px 18px;
    min-width: 100px;
    // line-height: 22px;
    text-align: center;
    border-radius: 8px;
    border: 0px;
    color: var(--black);

    // @media screen and (max-width: $small) {
    //     min-width: 80%;
    // }

    // @media screen and (max-width: $xs360) {
    //     min-width: 80px;
    //     padding: 7px 10px;
    // }
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(66.69deg, #fff -3.61%, #fff 24.87%, #fff 58.49%, #fff 83.71%);
        border-radius: 8px;
        z-index: -2;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-image: linear-gradient(
            273deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(208, 208, 208, 1) 100%,
            rgba(214, 214, 214, 1) 100%
        );
        border-radius: 8px;
        transition: all 0.3s;

        z-index: -1;
    }

    &:hover {
        color: var(--black);

        &:before {
            width: 100%;
        }
    }
}

/************** Default button Mixin ************/
@mixin buttonDefault {
    display: inline-block;
    transition: all 0.3s;
    position: relative;
    background: transparent;
    overflow: hidden;
    z-index: 1;
    outline: none;
    @include font-500(0.875rem);
    height: 38px;
    box-shadow: none !important;
    padding: 7px 18px;
    min-width: 100px;
    line-height: 22px;
    text-align: center;
    border-radius: 8px;
    border: 0px;
    color: var(--White);

    @media screen and (max-width: $xs360) {
        min-width: 80px;
        padding: 7px 10px;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: var(--gradient_primary);
        border-radius: 8px;
        z-index: -2;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-image: var(--gradient_primary_reverse);
        border-radius: 8px;
        transition: all 0.3s;

        z-index: -1;
    }

    &:hover {
        color: var(--White);

        &:before {
            width: 100%;
        }
    }
}

/************* Button Disable ************/
@mixin buttonDisable {
    &:after {
        background: var(--bg_gray_primary);
    }

    &:before {
        background: var(--bg_gray_primary);
    }

    &:hover {
        &:after {
            width: 0;
        }

        &:before {
            width: 0;
        }
    }
}

/*************** Button Size Mixin **************/
@mixin btnXSmall {
    padding: 1px 11px;
    font-size: 0.813rem;
    border-radius: 6px;
    height: 26px;
    min-width: 70px;
}

@mixin btnSmall {
    height: 34px;
    padding: 5px 14px;
    font-size: 0.813rem;
    min-width: 90px;
}

@mixin btnMedium {
    font-size: 0.875rem;
    height: 38px;
    min-width: 120px;
    line-height: 1.8;
}

@mixin btnLarge {
    font-size: 0.875rem;
    height: 41px;
    min-width: 150px;
    line-height: 28px;
}

@mixin btnXL {
    font-size: 0.875rem;
    height: 43px;
    min-width: 200px;
    line-height: 28px;
}

/************* Button bg-Color CSS *************/
@mixin primarybluegradient {
    &:after {
        background-image: linear-gradient(180deg, #5c87f7 0%, #042ebf 100%);
    }

    &::before {
        background-image: linear-gradient(0deg, #5c87f7 0%, #042ebf 100%);
    }
}

@mixin primarypurplegradient {
    &:after {
        background-image: var(--gradient_secondary);
    }

    &::before {
        background-image: linear-gradient(233deg, #4318bc 13.11%, #7222c9 86.43%);
    }
}

@mixin secondarybluebutton {
    &:after {
        background: var(--bpof_gradient_primary);
    }

    &:before {
        background: var(--bpof_gradient_secondary);
    }
}

@mixin secondarypinkbutton {
    &:after {
        background: var(--s4f_gradient_four);
    }

    &:before {
        background: var(--s4f_gradient_primary);
    }
}

@mixin buttonSecondary {
    &:after {
        background-image: var(--gradient_five);
    }

    &::before {
        background-image: linear-gradient(244deg, #373ecc 25.27%, #aa44e4 86.74%);
    }
}

/************ Button Twice Outline ************/

@mixin twiceButtonOutline {
    color: var(--gray_divider1);
    border: 1px solid;
    border-radius: 8px;
    background: transparent;
    @include font-500(0.875rem);
    height: 38px;
    padding: 7px 18px;
    min-width: 100px;
    text-align: center;
    display: inline-block;

    @media screen and (max-width: $xs360) {
        min-width: 80px;
        padding: 7px 10px;
    }

    &:hover {
        transition: all 0.3s;
        color: var(--purple_primary);
        border: 1px solid var(--purple_primary);
        background-color: #fbf1ff;
    }
}

@mixin twice-outline-btn-md {
    padding: 6px 15px;
    min-width: 90px;
}

@mixin twice-outline-btn-lg {
    min-width: 120px;
}

@mixin twice-outline-btn-xl {
    min-width: 150px;
    height: 41px;
}

/********************** Button Outline *************************/
@mixin buttonOutline {
    display: inline-block;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: 1px solid #c9d6df;
    border-radius: 8px;
    background: transparent;
    color: var(--text_fifth);
    @include font-500(0.875rem);
    height: 38px;
    text-align: center;
    padding: 7px 18px;
    line-height: 22px;
    min-width: 60px;

    @media screen and (max-width: $xs360) {
        min-width: 80px;
        padding: 7px 10px;
    }

    &:hover {
        transition: all 0.3s;
        color: var(--purple_primary);
        border: 1px solid var(--purple_primary);
        background-color: #fbf1ff;
    }
}

@mixin buttonOutlineLg {
    height: 40px;
    padding: 7px 12px;
    min-width: 130px;
}

@mixin buttonOutlinesm {
    padding: 7px 10px;
    min-width: 40px;
}

@mixin buttonOutlineXS {
    padding: 1px 11px;
    font-size: 0.813rem;
    border-radius: 6px;
    height: 26px;

    &:after,
    &:before {
        border-radius: 4px;
    }
}

/******************Text Heading ********************/
@mixin text-clip($text-gradient) {
    background: $text-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

@mixin flex-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

@mixin flex-box-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

@mixin flex-box-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-v-center {
    display: flex;
    align-items: center;
}

@mixin flex-v-start {
    display: flex;
    align-items: flex-start;
}

@mixin flex-v-end {
    display: flex;
    align-items: flex-end;
}

@mixin flex-around {
    display: flex;
    justify-content: space-around;
}

@mixin flex-h-between {
    display: flex;
    justify-content: space-between;
}

@mixin flex-evenly {
    display: flex;
    justify-content: space-evenly;
}

@mixin flex-h-center {
    display: flex;
    justify-content: center;
}

@mixin flex-h-start {
    display: flex;
    justify-content: flex-start;
}

@mixin flex-h-end {
    display: flex;
    justify-content: flex-end;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-row {
    display: flex;
    flex-direction: row;
}

@mixin font-900($font-size) {
    font-size: $font-size;
    font-weight: 900;
}

@mixin font-700($font-size) {
    font-size: $font-size;
    font-weight: 700;
}

@mixin font-600($font-size) {
    font-size: $font-size;
    font-weight: 600;
}

@mixin font-500($font-size) {
    font-size: $font-size;
    font-weight: 500;
}

@mixin font-400($font-size) {
    font-size: $font-size;
    font-weight: 400;
}

@mixin font-300($font-size) {
    font-size: $font-size;
    font-weight: 300;
}

@mixin font-200($font-size) {
    font-size: $font-size;
    font-weight: 200;
}

@mixin font-100($font-size) {
    font-size: $font-size;
    font-weight: 100;
}

@mixin bg-img($bg-img) {
    background-image: url($bg-img);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

@mixin img-object($img-fit) {
    width: 100%;
    height: 100%;
    object-fit: $img-fit;
    object-position: center;
}

@mixin img-contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
