@font-face {
    font-family: 'flaticon_ixfi-coin';
    src:
        url('https://dfwt0d2sx0pdr.cloudfront.net/reward/fonts/flaticon_ixfi-coin.ttf') format('truetype'),
        url('https://dfwt0d2sx0pdr.cloudfront.net/reward/fonts/flaticon_ixfi-coin.woff') format('woff'),
        url('https://dfwt0d2sx0pdr.cloudfront.net/reward/fonts/flaticon_ixfi-coin.woff2') format('woff2'),
        url('https://dfwt0d2sx0pdr.cloudfront.net/reward/fonts/flaticon_ixfi-coin.eot') format('embedded-opentype'),
        url('https://dfwt0d2sx0pdr.cloudfront.net/reward/fonts/flaticon_ixfi-coin.svg') format('svg');
}

i[class^='flaticon-']:before,
i[class*=' flaticon-']:before {
    font-family: flaticon_ixfi-coin !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-arrow:before {
    content: '\f101';
}
.flaticon-next:before {
    content: '\f102';
}
.flaticon-add:before {
    content: '\f103';
}
.flaticon-minus-square-outlined-button:before {
    content: '\f104';
}
.flaticon-tick:before {
    content: '\f105';
}
.flaticon-next-1:before {
    content: '\f106';
}
.flaticon-money:before {
    content: '\f107';
}
.flaticon-facebook:before {
    content: '\f108';
}
.flaticon-twitter:before {
    content: '\f109';
}
.flaticon-linkedin:before {
    content: '\f10a';
}
.flaticon-instagram:before {
    content: '\f10b';
}
.flaticon-youtube:before {
    content: '\f10c';
}
.flaticon-reddit:before {
    content: '\f10d';
}
.flaticon-conversation:before {
    content: '\f10e';
}
.flaticon-medium:before {
    content: '\f10f';
}
.flaticon-paper-plane:before {
    content: '\f110';
}
.flaticon-discord:before {
    content: '\f111';
}
.flaticon-check:before {
    content: '\f112';
}
.flaticon-home:before {
    content: '\f113';
}
.flaticon-envelope:before {
    content: '\f114';
}
.flaticon-sun:before {
    content: '\f115';
}
.flaticon-moon:before {
    content: '\f116';
}
.flaticon-play:before {
    content: '\f117';
}
.flaticon-repeat:before {
    content: '\f118';
}
.flaticon-right-arrow:before {
    content: '\f119';
}
.flaticon-right-arrows:before {
    content: '\f11a';
}
.flaticon-close:before {
    content: '\f11b';
}
.flaticon-calendar:before {
    content: '\f11c';
}
.flaticon-minus:before {
    content: '\f11d';
}
.flaticon-plus:before {
    content: '\f11e';
}
.flaticon-moon-1:before {
    content: '\f11f';
}
.flaticon-crescent-moon:before {
    content: '\f120';
}
.flaticon-info:before {
    content: '\f121';
}
.flaticon-clock:before {
    content: '\f122';
}
.flaticon-premium-services:before {
    content: '\f123';
}
.flaticon-world-wide-web:before {
    content: '\f124';
}
.flaticon-bell:before {
    content: '\f125';
}
.flaticon-wallet:before {
    content: '\f126';
}
.flaticon-wall-clock:before {
    content: '\f127';
}
.flaticon-sales:before {
    content: '\f128';
}
.flaticon-line-chart:before {
    content: '\f129';
}
.flaticon-shopping:before {
    content: '\f12a';
}
.flaticon-money-growth:before {
    content: '\f12b';
}
.flaticon-currency-exchange:before {
    content: '\f12c';
}
.flaticon-profit:before {
    content: '\f12d';
}
.flaticon-news:before {
    content: '\f12e';
}
.flaticon-business-card:before {
    content: '\f12f';
}
.flaticon-id:before {
    content: '\f130';
}
.flaticon-verified-account:before {
    content: '\f131';
}
.flaticon-affiliate-marketing:before {
    content: '\f132';
}
.flaticon-customer-service:before {
    content: '\f133';
}
.flaticon-logout:before {
    content: '\f134';
}
.flaticon-logout-1:before {
    content: '\f135';
}
.flaticon-eye:before {
    content: '\f136';
}
.flaticon-hidden:before {
    content: '\f137';
}
.flaticon-box:before {
    content: '\f138';
}
.flaticon-podium:before {
    content: '\f139';
}
.flaticon-history:before {
    content: '\f13a';
}
.flaticon-wallet-1:before {
    content: '\f13b';
}
.flaticon-show:before {
    content: '\f13c';
}
.flaticon-information-button:before {
    content: '\f13d';
}
.flaticon-power-button-sign:before {
    content: '\f13e';
}
.flaticon-gift-box:before {
    content: '\f13f';
}
.flaticon-history-1:before {
    content: '\f140';
}
.flaticon-redeem-points:before {
    content: '\f141';
}
.flaticon-podium-1:before {
    content: '\f142';
}
.flaticon-podium-2:before {
    content: '\f143';
}
.flaticon-to-do-list:before {
    content: '\f144';
}
.flaticon-eye-1:before {
    content: '\f145';
}
.flaticon-hidden-1:before {
    content: '\f146';
}
.flaticon-convert:before {
    content: '\f147';
}
.flaticon-calendar-1:before {
    content: '\f148';
}
.flaticon-30-days:before {
    content: '\f149';
}
.flaticon-clipboard:before {
    content: '\f14a';
}
.flaticon-report:before {
    content: '\f14b';
}
.flaticon-search:before {
    content: '\f14c';
}
.flaticon-target:before {
    content: '\f14d';
}
.flaticon-network:before {
    content: '\f14e';
}
.flaticon-wallet-2:before {
    content: '\f14f';
}
.flaticon-affiliate-marketing-1:before {
    content: '\f150';
}
.flaticon-arrow-right:before {
    content: '\f151';
}
.flaticon-reset:before {
    content: '\f152';
}
.flaticon-circular:before {
    content: '\f153';
}
.flaticon-option:before {
    content: '\f154';
}
.flaticon-share:before {
    content: '\f155';
}
.flaticon-right:before {
    content: '\f156';
}
.flaticon-danger:before {
    content: '\f157';
}
