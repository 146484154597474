@import '_theme.scss';
@import './variable';
@import './vendor_variable';

html,
body {
    font-size: 16px;

    @media screen and (max-width: $width767) {
        font-size: 14px;
    }
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--bg_primary2) !important;
    color: var(--text_fifth);
}

.form-check-input {
    background-color: var(--sub_text_secondary);
    border-color: var(--gray_divider3);
    outline: none;

    &:checked {
        background-color: var(--purple_primary);
        border-color: var(--purple_primary);
        outline: none;
        box-shadow: none;
    }

    &:focus {
        outline: none;
        border-color: var(--gray_divider3);
        box-shadow: none;
    }
}

.myAccount-text,
.notification-text {
    display: none;
}

.pointer {
    cursor: pointer;
}

.progress-bar {
    background: var(--purple_primary);
}

.progress {
    height: 0.5rem;
    margin-top: 6px;
}

.font14 {
    font-size: 0.875rem;
}
.font12 {
    font-size: 12px !important;
}

.page-heading {
    margin: 0;
    font-size: 1.75rem;
    line-height: 1.2;
    color: var(--purple_primary);
}

.subheading {
    font-size: 2rem;
}

.w-100 {
    width: 100% !important;
}

.form-label {
    margin-bottom: 3px;
    font-size: 0.875rem;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: var(--purple_primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
}

.gray-bg {
    .trade-card {
        margin-top: 0px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.mt-header {
    min-height: calc(100vh - 494px);
}

.coin-name {
    line-height: 1.3;
    margin: 0px 10px 0px 0px;
}

.marqueMoveing {
    @include flex-h-between;
    background: var(--bg_gray_primary);
    color: var(--White);
    width: 100%;
    padding: 10px 5px;
    border-top: solid 1px var(--icon_gray);
    border-bottom: solid 1px var(--icon_gray);
    margin-bottom: 0px;
    bottom: 0px;
    position: absolute;

    .coin-icon-cover {
        border-right: solid 1px var(--White);
        padding-right: 6px;
        margin-right: 8px;
        font-size: 0.875rem;
    }

    .coin-icon {
        span {
            background: var(--White);
            border: solid 1px var(--White);
            display: inline-block;
            padding: 3px;
            border-radius: 35px;
            margin-right: 6px;

            img {
                border-radius: 50px;
                width: 28px;
                height: 28px;
            }
        }
    }

    .price {
        margin-right: 10px;
    }
}

/*============================== Re Usable css =======================================*/
.btn:focus {
    box-shadow: none !important;
}

.success {
    background: var(--green_color);
}

.primary {
    color: var(--purple_primary);
}

.text-success {
    color: var(--green_color);
}

.dangure,
.danger {
    background: var(--red_color);
}

.success-bg {
    background: var(--green_color);
}

.danger-bg {
    background: var(--red_color);
}

.neutral-bg {
    background: var(--icon_gray);
}

.whitebg {
    background: var(--White);
}

.user-select-none {
    user-select: none;
}

.nowrap {
    white-space: nowrap;
}

.card {
    border-radius: 15px;
}

.pointer {
    cursor: pointer;
}

.accordion-button:focus {
    box-shadow: none;
}

.pdding-table-heading {
    padding-top: 1px;
}

.page-link {
    &:focus {
        box-shadow: none !important;
    }
}

.font500 {
    font-weight: 500;
}

.font600 {
    font-weight: 600;
}

.form-control:focus {
    box-shadow: none;
}

.btn-primary {
    background: $primaryGradient;
    color: var(--White);
    border: 0px;

    &:hover {
        color: var(--White);
    }
}

// .btn-back {
//     background: $primaryGradient;
//     color: var(--White);
//     padding: 8px;
//     border: 0px;

//     &:hover {
//         color: var(--White);
//     }
// }

// .btn-outline-primary {
//     color: var(--purple_primary);
//     border-color: var(--purple_primary);

//     &.dropdown-toggle.show {
//         background: $primaryGradient;
//         border-color: var(--purple_primary)!important;
//         color: var(--White);
//     }

//     &:hover {
//         background: $primaryGradient;
//         border-color: var(--purple_primary)!important;
//         color: var(--White);
//     }
// }
.button-default {
    background-image: var(--gradient_primary);
    background-size: 100% 100%;
    transition: all 0.4s ease-in-out;
    padding: 7px 30px;
    height: 38px;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    @include font-500(00.875rem);
    font-style: normal;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: center;
    border-radius: 50px;
    border: 0px;
    color: var(--White);

    &:hover {
        background-size: 200% 100%;
        color: var(--White);
        text-decoration: none;
        background-position: 100% 0;
        transition: ease all 0.5s;
    }
}

.border-radius-100 {
    border-radius: 100px;
}

.btn-close {
    box-shadow: none !important;
}

.button-border-gray {
    border-color: var(--gray-primary);
    color: var(--black);
}

.text-medium {
    font-weight: 500;
}

.darkTheme {
    .swiper-pagination-bullet {
        background: var(--text_fifth);
    }
}

em[class^='flaticon-']:before,
em[class*=' flaticon-']:before {
    font-family: flaticon_ixfi-coin !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.btn-primary {
    background: var(--gradient_third);
    border: 0;
    border-radius: 58px;
    color: var(--White);

    font-size: 14px;
}

.btn-secondary {
    font-size: 14px;
    border-radius: 16px;
}

.button-orange {
    background: var(--gradient_primary);
    border-radius: 25.5px;
    padding: 7px 20px 7px 10px;
    height: 37px;
    color: var(--White);
    border: none;

    &:hover {
        background: var(--gradient_primary_reverse);
        transition: ease all 0.5s;
        color: var(--White);
        text-decoration: none;
    }
}

.modal-fullpage {
    width: 90%;
    max-width: 1900px;
}

.darkTheme {
    .modal-content {
        // background: var(--purple_2_20);

        .modal-header,
        .modal-footer {
            // border-color: var(--icon_gray);
        }
    }
}

/*============================== End Re Usable CSS =========================================== */

/*===================== Scrollbar CSS Start ========================*/

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px var(--purple_color_40) !important;
}

::-webkit-scrollbar-thumb {
    background-color: var(--purple_color_30) !important;
}

/*===================== Scrollbar CSS End =================================*/
/*===================== Common CSS Start ==================================*/
.card-padding {
    padding: 50px;
    padding: 35px 35px 25px 35px;
    margin-bottom: 35px;

    @media screen and (max-width: $large) {
        padding: 30px 0px 20px 0px;
    }
}

.card-shadow {
    box-shadow: 0px 2px 8px #3461ff40;
    border: 0px;
}

.inner-page-text {
    p {
        @include font-400(13px);
    }
}

/*===================== Common CSS End =======================================*/
/*======================= Inner Banner CSS Start =============================*/
.inner-hero-banner {
    padding: 110px 0px 50px 0px;

    @media screen and (max-width: $small) {
        padding: 100px 0px 50px 0;
    }

    .point-cover {
        display: flex;
        background: var(--purple_color-dark);
        border-radius: 35px;
        padding: 10px;

        @media screen and (max-width: $large) {
            align-items: center;
            border-radius: 50px;
        }

        @media screen and (max-width: $xs400) {
            flex-direction: column;
            border-radius: 20px;
        }
    }

    .point-round {
        background: var(--purple_color);
        width: 40px;
        height: 40px;
        @include flex-center;
        border-radius: 35px;
        margin-right: 10px;

        @media screen and (max-width: $large) {
            width: 30px;
            height: 30px;
            margin-right: 5px;
        }

        @media screen and (max-width: $small) {
            width: 25px;
            height: 25px;
        }

        @media screen and (max-width: $xs400) {
            margin-right: 0px;
        }

        img {
            width: 30px;

            @media screen and (max-width: $large) {
                width: 20px;
            }
        }

        &.wallet-icon {
            img {
                width: 25px;

                @media screen and (max-width: $large) {
                    width: 15px;
                }
            }
        }
    }

    .price-totals {
        text-align: center;
        padding-right: 15px;

        @media screen and (max-width: $large) {
            padding-right: 8px;
        }

        @media screen and (max-width: $small) {
            padding-right: 1px;
            font-size: 16px;
        }

        @media screen and (max-width: $xs400) {
            padding-top: 5px;
        }

        .total-amount {
            @include flex-v-center;
            @include font-500(18px);
            color: var(--White);
            line-height: 1.4;
            justify-content: space-between;

            h2,
            em {
                @include font-500(18px);
                color: var(--White);
                line-height: 1.4;
                margin-bottom: 0;
            }
        }

        span {
            @include font-400(12px);
            color: var(--White);
            display: block;

            @media screen and (max-width: $small) {
                font-size: 11px;
            }
        }
    }

    .total-avalible-point-cover {
        display: flex;

        @media screen and (max-width: $xs575) {
            margin: 10px auto 0px auto;
        }

        @media screen and (max-width: $xs450) {
            margin: 10px auto 0px auto;
        }

        @media screen and (max-width: $medium) {
            gap: 10px;
        }
    }

    &.earnpage-banner {
        padding-bottom: 300px;

        @media screen and (max-width: $small) {
            padding-bottom: 280px;
        }
    }

    .heading-text {
        color: var(--White);
        @include font-600(14px);
        margin-top: 5px;
        margin-bottom: 0;

        a {
            color: var(--sub_text_secondary2) !important;
            font-weight: 400;
        }

        @media screen and (max-width: $width767) {
            text-align: center;
        }

        em {
            &::before {
                vertical-align: middle;
            }
        }
    }

    .inner-hero-heading {
        @include font-500(26px);
        color: var(--White);

        // @media screen and (max-width: $extraExtraLarge) {
        //     font-size: 37px;
        // }

        @media screen and (max-width: $extraLarge) {
            font-size: 27px;
        }

        @media screen and (max-width: $medium) {
            font-size: 20px !important;
            margin-bottom: 15px;
        }

        span {
            @include text-clip(var(--gradient_five));
            font-weight: 600;
        }
    }
}

/*======================= Inner Banner CSS End ==============================*/
/*======================= Monthly Tasks CSS Start ==========================*/
.monthly-block {
    background: var(--bg_primary2);
    border: solid 1px var(--gray_divider3);
    box-shadow: var(--view_shadow_regular_dark_01);
    border-radius: 20px;
    position: relative;
    z-index: 1;
    padding: 35px 35px 35px 35px;
    margin-bottom: 35px;

    .swiper-slide {
        img {
            width: 14px;
            height: 100%;
        }
    }

    @media screen and (max-width: $xs400) {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .monthly-card {
        border: solid 1px var(--gray_divider3);
        border-radius: 20px;

        .monthly-heading {
            @include font-600(16px);
            color: var(--text_fifth);

            @media screen and (max-width: $extraLarge) {
                font-size: 16px;
            }
        }

        .heading-text {
            font-size: 13px;
            color: var(--text_fifth);

            @media screen and (max-width: $large) {
                @include flex-h-between;
            }

            @media screen and (max-width: 490px) {
                display: block;
            }

            span {
                font-weight: 600;
            }
        }

        .end-text {
            @include font-600(15px);
            color: var(--text_fifth);
        }

        .timer-card {
            .End-timer {
                .timer-section {
                    background: var(--bg_popup);
                    border: 2px solid var(--gray_divider3);
                    width: 48px;
                    min-width: 38px;
                    text-align: center;
                    line-height: 1;
                    border-radius: 7px;
                    padding: 7px 5px;
                    margin: 0px 5px;

                    @media screen and (max-width: $extraLarge) {
                        padding: 5px 2px;
                        margin: 0px 2px;
                        width: 40px;
                    }

                    strong {
                        margin-bottom: -2px;
                        display: block;
                        @include font-600(14px);
                        color: var(--text_fifth);
                    }

                    p {
                        @include font-400(12px);
                        font-style: normal;
                        margin: 0;
                        color: var(--text_fifth);
                    }

                    .days-text {
                        @include font-500(10px);
                        color: var(--text_fifth);
                    }
                }
            }
        }

        .button-secondary-trade {
            background: var(--gradient_five);
            border-radius: 17.5px;
            @include font-500(13px);
            color: var(--White);
            border: 0px;
            padding: 8px 16px;
            letter-spacing: 0.5px;
            min-width: 45%;
            transition: all 0.4s ease-in-out;

            &:hover {
                background-position: 100% 0;
                background-size: 200% 100%;
                -moz-transition: all 0.4s ease-in-out;
                -o-transition: all 0.4s ease-in-out;
                -webkit-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
            }
        }

        .rules-btn {
            background: var(--bg_secondary2);
            color: var(--text_fifth);
            border-radius: 8px;
            border: 0px;
            padding: 8px 24px;
            @include font-600(14px);
            letter-spacing: 0.5px;
        }
    }

    .range-block {
        margin-top: -35px;
        margin-right: 20px;

        @media screen and (max-width: $large) {
            margin-top: 70px;
        }

        @media screen and (max-width: $medium) {
            padding-left: 10px;
            padding-right: 20px;
            margin-top: 70px;
        }

        @media screen and (max-width: 476px) {
            margin-right: 0;
        }
    }

    .range-bar-cover {
        .range {
            background-color: #ebe3ff;
        }

        .circle {
            position: absolute;
            background-color: #e0e0e0;
            top: -20px;
            border-radius: 50%;
            width: 48px;
            height: 48px;
            transform: translateX(-50%);
            padding: 7px;
            @include flex-center;

            &:last-child {
                transform: translateX(-50%);
            }

            @media screen and (max-width: 476px) {
                width: 25px;
                height: 25px;
                top: -10px;

                .list-coin {
                    width: 15px;
                    height: 15px;
                }
            }
        }

        .circle {
            &.achievedSteps {
                background-color: var(--bg_primary2);
                border: solid 3px rgb(174 226 176) !important;

                .points-block {
                    background: rgb(174 226 176) !important;
                    color: var(--black) !important;
                    font-weight: 500;

                    &::after {
                        border-top-color: rgb(174 226 176) !important;
                    }
                }
            }
        }

        .points-block {
            position: absolute;
            @include font-500(11px);
            text-align: center;
            bottom: 45px;
            width: 85px;
            background-color: #e0e0e0;
            color: #707070;
            border-radius: 12px;
            padding: 7px 5px 6px 5px;
            line-height: 1;
            transform: translateX(-50%);
            left: 50%;
            margin-bottom: 15px;

            &::after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 7px solid #e0e0e2;
                position: absolute;
                top: 24px;
                right: 42%;
            }

            @media screen and (max-width: $large) {
            }

            @media screen and (max-width: $medium) {
                width: 75px;
                border-radius: 12px;
                line-height: 1;
                padding: 6px 5px;
                margin-bottom: 1px;
                bottom: 65px;

                &::after {
                    top: 23px;
                }
            }

            @media screen and (max-width: $small) {
                bottom: 60px;
                font-size: 9px;

                &::after {
                    top: 21px;
                }
            }

            @media screen and (max-width: 476px) {
                bottom: 35px;
                width: auto;
                border-radius: 5px;

                &::after {
                    top: 30px;
                    right: 30%;
                }

                span {
                    display: block;
                }
            }
        }

        .reword-redeem {
            display: flex;
            margin-top: 30px;
            @include font-500(14px);

            @media screen and (max-width: 476px) {
                margin-top: 5px;
                font-size: 11px;
            }
        }

        .reword-redeem-1 {
            top: 60px;
            position: absolute;
            display: flex;
            transform: translateX(-50%);
            left: 50%;
            @include font-500(13px);

            @media screen and (max-width: 476px) {
                top: 30px;
            }
        }
    }

    .trade-total {
        position: absolute;
        @include flex-h-end;
        bottom: -2px;
        right: -31px;
        width: 100%;
        padding: 2px;
        text-align: center;

        @media screen and (max-width: $large) {
            position: relative;
            flex-direction: column;
            justify-content: center;
            border-radius: 0px;
            padding: 0px;
            right: 0px;
            margin-top: 20px;
        }

        p {
            font-size: 13px;
            margin-bottom: 0px;

            padding: 5px 25px 3px 15px;
            border-radius: 8px 8px 0px 0px;

            @media screen and (max-width: $large) {
                border-radius: 0;
                background: #e4d8fb;
            }

            span {
                font-weight: 600;
                padding-left: 4px;
            }
        }
    }
}

.task-wrapper {
    position: relative;
    overflow: hidden;
}

.monthly-card {
    overflow: hidden;

    .monthly-details {
        padding: 25px;
        border-radius: 20px 0px 0px 20px;
        color: var(--black);
        @include flex-box-column;

        @media screen and (max-width: $large) {
            border-radius: 0 0 17px 17px;
            align-content: center;
            @include flex-box-column;
        }

        @media screen and (max-width: $medium) {
            padding: 15px;
            border-radius: 0px 0px 17px 17px;
        }
    }

    .milestone-task {
        padding: 25px 25px 60px 25px;
    }

    .left-task-area {
        padding: 35px;

        @media screen and (max-width: $extraExtraLarge) {
            padding: 25px;
        }

        @media screen and (max-width: $medium) {
            padding: 15px;
            width: 100%;
        }

        .task-completed-icon {
            top: 5%;
            right: 5%;
            color: var(--White);
            padding: 3px;
            border-radius: 50px;
            background: var(--green_color);
            font-size: 8px;
            height: 20px;
            width: 20px;
            position: absolute;
            line-height: 0.2;
            @include flex-center;
        }

        @media screen and (max-width: $xs300) {
            padding: 16px;
        }
    }

    .rule-in-out {
        position: absolute;
        left: -100%;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        transition: ease all 0.5s;
        background: var(--bg_secondary2);
        padding: 25px;
        border-radius: 20px 0px 0px 20px;
        z-index: 9;

        @media screen and (max-width: $medium) {
            padding: 15px;
            border-radius: 0px 0px 17px 17px;
        }

        &.flip-rule {
            left: 0%;
        }

        .overflow-auto {
            height: calc(100% - 30px);
        }

        h4 {
            margin-bottom: 5px;
            margin-top: 3px;
            @include font-600(16px);
            @include text-clip(linear-gradient(171deg, #8b37f7 -8.89%, #fa8662 72.43%));
        }

        p {
            font-size: 14px;
            color: var(--text_fifth);

            @media screen and (max-width: $medium) {
                font-size: 12px;
            }
        }

        .rule-close {
            float: right;
            position: absolute;
            right: 0px;
            top: 0;
            border-radius: 0 0 0 55%;
            border: none;
            color: var(--White);
            font-size: 8px;
            padding: 5px 10px;
            background: var(--purple_primary);
        }
    }
}

/*======================= Monthly Tasks CSS End ==============================*/
/*======================= Mobile Monthly Tasks CSS Start ======================*/
.mobile-monthly-task {
    .range-block {
        padding-right: 30px;
    }

    .swiper-button-next {
        top: 10px;
        right: 0;
        font-size: 16px;
        background: none !important;

        &::after {
            font-size: 16px;
        }
    }

    .swiper-button-prev {
        top: 10px;
        left: 0;
        background: none !important;

        &::after {
            font-size: 16px;
        }
    }

    .swiper {
        .swiper-pagination-horizontal {
            width: 100% !important;
            bottom: -5px;
        }
    }
}

/*======================= Mobile Monthly Tasks CSS End =======================*/

/*======================= Dark Theme Monthly Tasks CSS Start ==================*/
.card {
    background: var(--bg_primary5);
    border: 1px solid var(--gray_divider3);
    box-shadow: var(--shadow);
    color: var(--text_fifth);
}

.card-heading-spacing {
    margin-bottom: 20px;

    @media screen and (max-width: $large) {
        padding: 0px 20px 0px 20px;
    }

    p {
        margin-bottom: 0px;
    }
}

/*======================= Dark Theme Monthly Tasks CSS End ====================*/
/*========================= Index Page coming soon CSS Start ==================*/
.timer-main {
    background: var(--black);
    color: var(--White);
    padding: 3px 19px;
    border-radius: 25px;
    margin-bottom: -14px;
    z-index: 9;
    position: absolute;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    left: 10%;
    top: 0px;
    transition: ease all 0.5s;

    @media screen and (max-width: $xs400) {
        padding: 3px 12px;
        width: 91%;
        left: 6px;
        top: 8px;
    }

    img {
        width: 14px !important;
        height: 14px;
        @media screen and (max-width: $xs300) {
            width: 10px !important;
            height: 10px;
        }
    }

    .timer-detail {
        @include flex-v-center;
    }

    .days-text {
        font-size: 12px;
        padding-left: 9px;

        @media screen and (max-width: $xs450) {
            font-size: 10px;
            padding-left: 6px;
        }

        @media screen and (max-width: 340px) {
            font-size: 8px;
            padding-left: 3px;
        }
    }
}

/*========================= Index Page coming soon CSS End ===================*/
/*========================= View all button CSS Start  =======================*/
.view-all {
    @include font-500(14px);
    color: var(--purple_primary);
    transition-duration: 0.5s;

    &:hover {
        color: var(--purple_primary);
    }

    img {
        width: 20px;
        height: 20px;
        margin-left: 10px;

        @media screen and (max-width: $small) {
            width: 18px;
            height: 18px;
            margin-left: 5px;
        }
    }

    @media screen and (max-width: $medium) {
        font-size: 13px;
        margin-top: 10px;
    }

    @media screen and (max-width: $small) {
        padding-bottom: 13px;
    }

    em {
        color: #b6b7c2;
        font-size: 12px;
        padding-left: 5px;
        padding-right: 5px;

        @media screen and (max-width: $medium) {
            font-size: 10px;
        }
    }

    &:hover em {
        color: var(--purple_primary);
    }
}

/*========================= View all button CSS End =========================*/
/*========================= Ongoing Rewards CSS Start =======================*/
.single-item-card {
    border-radius: 15px;
    transform: translateY(0px);
    transition: ease all 0.5s;
    box-shadow: var(--tr-shadow);
    width: 100%; // for small view center slide zoom
    @media screen and (max-width: $large) {
        margin-top: 7px;
        margin-bottom: 7px;
    }
    .cursor-pointer {
        span,
        strong,
        label {
            cursor: pointer;
        }
    }

    h6 {
        font-size: 14px;
        text-align: center;

        @media screen and (max-width: $xs325) {
            font-size: 10px;
        }
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 4px 4px -3px #b0acacf7;
        transition: ease all 0.5s;

        @media screen and (max-width: $medium) {
            transform: none;
            box-shadow: none;
            transition: none;
        }

        .out-of-stock-icon {
            transform: translateY(-5px);
        }

        .card-item-img {
            img {
                transform: translateY(-5px);
                transition: ease all 0.5s;
            }
        }

        .card-title,
        .claimed-text,
        .range,
        .reword-redeem,
        // .button-icon,
        .timer-cover {
            transform: translateY(-5px);
            transition: ease all 0.5s;
        }

        .sold-out-label {
            transform: translateY(-3px);
            transition: ease all 0.5s;
        }
    }

    .card-title {
        color: var(--text_fifth);
        @include font-600(16px);
        transition: ease all 0.5s;
        margin: 5px 0px 12px 0px;
        line-height: 1.2;
        display: block;

        @media screen and (max-width: $large) {
            font-size: 16px;
        }

        @media screen and (max-width: $medium) {
            font-size: 16px;
            margin: 5px 0px 5px 0px;
        }

        @media screen and (max-width: $small) {
            font-size: 12px;
            margin: 5px 0px 5px 0px;
        }

        @media screen and (max-width: $xs325) {
            font-size: 10px;
        }
    }

    .card-item-img {
        margin: 14px 14px 0px 14px;
        border-radius: 15px;
        overflow: hidden;
        transition: ease all 0.5s;
        @media screen and (max-width: $xs350) {
            margin: 7px 7px 0px 7px;
        }
        video {
            width: 100%;
            border-radius: 13px;
        }

        img {
            border-radius: 15px;
            transition: ease all 0.5s;
        }
    }

    .sold-out-label {
        transform: translateY(0px);
        transition: ease all 0.5s;
    }
}

/*========================= Ongoing Rewards CSS End ========================*/
/*========================= View All Ongoing Rewards CSS only =======================*/
.view-all-card {
    .single-item-card {
        @media screen and (max-width: $large) {
            margin-top: 0;
            margin-bottom: 0;
        }
        @media screen and (max-width: $xs400) {
            margin-bottom: 7px;
        }
    }
}

/*========================= View All Ongoing Rewards CSS END =======================*/
/*========================= Pagination CSS Start ===========================*/
.ixfi-pagination {
    .pagination {
        @media screen and (max-width: $medium) {
            justify-content: center;
        }

        .page-item {
            &.active {
                .page-link {
                    color: var(--White);
                    background-color: var(--purple_primary);
                    border-color: var(--purple_primary);
                }
            }

            .page-link {
                padding: 5px 13px;
                font-size: 14px;
                color: var(--bg_secondary5);
                background: var(--bg_primary5);
                border-color: var(--gray_divider3);
            }
        }
    }
}

/*========================= Pagination CSS End ============================*/
/*======================== For Angular Card Swiper CSS Start =============*/

.custom-swiper {
    width: 100%;
}

/*======================== For Angular Card Swiper CSS End ===============*/

.images-flexible {
    padding-bottom: 120%;
    display: block;
    height: 0px;
    border-radius: 15px;
    overflow: hidden;
}

.fit-image {
    min-width: 100%;
    max-width: 100%;
    object-fit: cover;
}

.overlap-card {
    height: 100%;
    z-index: 1;
    width: 100%;
    position: absolute;
    background: linear-gradient(360deg, #000 0%, #0a070b00 50%, #0a070b00 100%);
    border-radius: 15px;
    bottom: 0px;

    .overlay-card-text {
        position: absolute;
        bottom: 20px;
        text-align: center;
        width: 100%;

        h5 {
            @include font-600(16px);
            margin-bottom: 0px;
            color: var(--White);
        }

        .overlay-small-text {
            img {
                background: var(--reward_gradient_blue_purple);
                border-radius: 38px;
                margin-right: 5px;
                width: 18px !important;
                display: inline-block !important;
                height: 18px;
                padding: 3px;
            }

            span {
                color: var(--sub_text_secondary);
                @include font-500(12px);
            }
        }
    }
}

/*--------------- Date Picker ------------------------*/
.bs-datepicker {
    background: var(--bg_primary5) !important;
}

.bs-datepicker-body {
    border-color: var(--gray_divider4) !important;
}

.bs-datepicker-body table td {
    // color: var(--White);
    padding: 2px !important;
}

.bs-datepicker-body table td span.is-other-month,
.bs-datepicker-body table td.is-other-month span {
    color: var(--sub_text_primary) !important;
}

.bs-datepicker-body table th {
    color: var(--text_fifth) !important;
}

.bs-datepicker-body table.days td.active:not(.select-start):before,
.bs-datepicker-body table.days td.in-range:not(.select-start):before,
.bs-datepicker-body table.days span.active:not(.select-start):before,
.bs-datepicker-body table.days span.in-range:not(.select-start):before {
    background: var(--bg-transparent) !important;
}

.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
    color: var(--text_primary);
    background: var(--bg_secondary2) !important;
}

.bs-datepicker-body table.days td.active:not(.select-start):before,
.bs-datepicker-body table.days td.in-range:not(.select-start):before {
    background: #4f5051;
}

.bs-datepicker-body table td.week span {
    color: var(--purple_primary) !important;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-head,
.bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*='select-']:after,
.bs-datepicker-body table td[class*='select-'] span:after,
.bs-datepicker-body table td.active-week span:hover {
    background-color: var(--purple_2) !important;
}

.bs-datepicker-head button:hover {
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.bs-datepicker-container {
    padding: 0 !important;
}

.bs-datepicker-custom-range {
    background: var(--bg_primary5) !important;
}

.bs-datepicker-predefined-btns button {
    background-color: var(--purple_2) !important;
    font-size: 14px;

    &:hover {
        color: var(--White);
    }
}

.bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
    left: 1px;
    right: 1px;
}

/*=========== Lottie img radius CSS Start ==============*/
.lottie-radius {
    svg {
        border-radius: 15px;
    }
}

/*============ Lottie img radius CSS End ==============*/

/*================== For Detail Page Lottie CSS Start =================*/

.lottie-custom {
    // max-width: 300px;
    width: 100% !important;
    height: auto !important;

    svg {
        border-radius: 28px 0 0 28px;

        @media (max-width: $large) {
            border-radius: 28px 28px 0 0;
        }
    }
}

/*================== For Detail Page Lottie CSS End =================*/
/*================== For No-data Lottie CSS Start ===================*/
.no-data {
    max-width: 300px;
}

/*================== For No-data Lottie CSS End =================*/
/*============= Close Button Animation CSS Start =============*/

.popup-aniamtion {
    .btn-animation {
        //position: absolute;
        top: 15px;
        right: 15px;
        z-index: 999999;
        width: 20px;
        height: 20px;
        line-height: 44px;
        position: absolute;
        // right: 15px;
        // top: 15px;
        border: none;
        outline: none;
        text-decoration: none;
        text-align: center;
        opacity: 0.65;
        padding: 0 0 18px 10px;
        color: var(--White);
        background: transparent;
        font-style: normal;
        font-size: 28px;
        font-family: Arial, Baskerville, monospace;

        &::before {
            content: '';
            position: absolute;
            height: 1.3px;
            width: 80%;
            top: 46%;
            left: 1px;
            transform-origin: 50% 50%;
            background-color: var(--black);
            opacity: 1;
            -moz-transition: -moz-transform ease 0.25s;
            -webkit-transition: -webkit-transform ease 0.25s;
            -o-transition: -o-transform ease 0.25s;
            -ms-transition: -ms-transform ease 0.25s;
            transition: transform ease 0.25s;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &::after {
            content: '';
            position: absolute;
            height: 1.3px;
            width: 80%;
            top: 46%;
            left: 1px;
            transform-origin: 50% 50%;
            background-color: var(--black);
            opacity: 1;
            -moz-transition: -moz-transform ease 0.25s;
            -webkit-transition: -webkit-transform ease 0.25s;
            -o-transition: -o-transform ease 0.25s;
            -ms-transition: -ms-transform ease 0.25s;
            transition: transform ease 0.25s;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        &:hover:before {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        &:hover:after {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

/*============= Close Button Animation CSS End =============*/

/*Product page info message*/
.info-message {
    background: rgb(139 55 247 / 9%);
    border: solid 1px var(--purple_primary);
    padding: 5px 10px;
    border-radius: 10px;
    @include flex-v-center;
    font-size: 12px;
    line-height: 1.2;

    em {
        background: var(--purple_primary);
        width: 20px;
        min-width: 20px;
        height: 20px;
        margin-right: 5px;
        border-radius: 20px;
        display: inline-block;
        text-align: center;
        line-height: 18px;
        vertical-align: middle;
        color: white;
        font-size: 9px;

        &::before {
            vertical-align: middle;
        }
    }
}

/* ==================== Particle Js Animation Start ========================*/
#particles-js-1,
#particles-js-2,
#particles-js-3,
#particles-js-4,
#particles-js-5,
#particles-js-6,
#particles-js-7,
#particles-js-8,
#particles-js-9 {
    position: absolute;
    width: 100%;
    height: 100%;
    @include bg-img('https://dfwt0d2sx0pdr.cloudfront.net/reward/title-banner.jpg');

    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    z-index: 5;

    @media screen and (max-width: $small) {
        background-size: auto 150%;
    }
}

// .hero-banner {
//     display: flex;
//     align-items: center;
//     position: relative;
//     padding: 10px 0px 0px 0px;
//     width: 100%;
//     background: #1f0735;
//     background-size: cover;
//     height: calc(100vh - 428px);
//     min-height: 600px;

//     @media screen and (max-width: $large) {
//         height: auto;
//         min-height: auto;
//         padding: 100px 0px;
//         max-height: 480px;
//     }

//     @media screen and (max-width: $medium) {
//         flex-direction: column;
//         padding: 50px 0px 20px 0px;
//         max-height: none;
//     }
// }
.inner-hero-banner {
    position: relative;
    padding-top: 0px;
    z-index: 0;

    .container {
        padding-top: 130px;
        z-index: 99;
        position: relative;
    }
}

.product-main .detail-main .detail-area {
    z-index: 55;
    position: relative;
}

.banner-inner {
    padding-bottom: 0px;
    background-size: cover;
    background-position: center center;
    min-height: 263px;

    .container {
        padding-top: 70px;
    }
}

.monthly-banner {
    max-width: 410px;
    width: 100%;

    @media screen and (max-width: $large) {
        max-width: 250px;
    }

    @media screen and (max-width: $medium) {
        max-width: 190px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

/*************** Modal Box CSS Start ************/
.modal-content {
    border-radius: 16px;
    background: var(--bg_primary5);
    border: none;

    h5 {
        @include font-600(18px);
        margin-bottom: 0;
        margin-right: 5px;
        @include text-clip(var(--gradient_secondary));
        @media screen and (max-width: $xs320) {
            font-size: 15px;
        }
    }

    h4 {
        @include font-700(28px);

        @media screen and (max-width: $small) {
            font-size: 24px;
        }

        @media screen and (max-width: $xs420) {
            font-size: 20px;
        }
    }

    .modal-header {
        padding: 20px 0 15px 0;
        margin: 0 25px;
        border-bottom: 1px solid var(--gray_divider3);
        @media screen and (max-width: $xs320) {
            margin: 0 15px;
        }
        .btn-close {
            border-radius: 50px;
            text-align: center;
            @include flex-center;
            width: 10px;
            height: 10px;
            opacity: 1;
            z-index: 1;
            transition-duration: 0.5s;
            &:hover {
                transform: rotate(90deg);
                transition-duration: 0.5s;
            }

            em {
                font-size: 10px;
                @include text-clip(var(--gradient_secondary));
                display: flex;
            }
        }
    }

    .modal-body {
        padding: 20px 25px 0 25px;
        margin-bottom: 25px;
        @media screen and (max-width: $xs320) {
            padding: 20px 15px 0 15px;
        }

        p {
            font-size: 0.875rem;
        }

        .font-16 {
            font-size: 16px;

            @media screen and (max-width: $small) {
                font-size: 14px;
            }
        }
    }

    .modal-btn {
        .btn-lg,
        .btn-xl,
        .btn-twice-outline-xl {
            @media screen and (max-width: $xs360) {
                min-width: 100px;
            }
        }
    }

    .icon-width-nav {
        width: 60px;
        margin-bottom: 10px;
    }

    .modal-footer {
        padding: 20px 0 15px 0;
        margin: 0 25px;
        border-top: 1px solid #d1d1d1;
    }
}

.white-text {
    h5,
    h4 {
        @include text-clip(var(--White));
    }

    p {
        color: var(--White);
    }
}

.bpof-winner-popup {
    .btn-close {
        background-color: var(--airdrop_bg_secondary);
        background-image: none;
        line-height: 0.5;

        em {
            font-size: 10px;
            @include text-clip(var(--bpof_text_gradient));
        }
    }
}

/*************** Modal Box CSS End *************/

/********** Task Center & Recurring Task MOdal CSS Start **********/
.ixfi-modal {
    .modal-content {
        .modal-header {
            h5 {
                @include text-clip(var(--gradient_secondary));
            }

            .btn-close {
                background-color: var(--bg_secondary4);
                background-image: none;

                em {
                    @include text-clip(var(--gradient_secondary));
                }
            }
        }

        .description-text {
            @include text-clip(var(--bg_secondary5));
            font-weight: 600;
        }

        .task-center-bg {
            padding: 15px;
            background: var(--bg_secondary3);
            border-radius: 14px;
            ul {
                padding-left: 15px;
                li {
                    line-height: 1.3;
                    padding: 3px 0px;
                }
            }
            &.warning-bg {
                background: #ff9e001a;
            }
            &.error-bg {
                background: #e2563826;
            }
        }

        .btn-default:not(.btn-disable) {
            @include primarypurplegradient;
        }
    }
}

/********** Task Center & Recurring Task MOdal CSS End **********/
.modal-text {
    color: var(--text_fifth);
    font-size: 13px !important;
    line-height: 1.6;
}

.modal-main-img {
    width: 120px;
    height: 120px;
    margin-left: 15px;

    @media screen and (max-width: $small) {
        display: none;
    }
}

.modal-secondary-img {
    width: 75px;
    height: auto;
    margin-left: 10px;

    @media screen and (max-width: $small) {
        display: none;
    }
}

.modal-list {
    li {
        color: var(--text_fifth);
        font-size: 13px;
        text-align: left;
        padding-bottom: 8px;
    }
}

.modal-input {
    background-color: var(--bg_primary4) !important;
    border: 1px solid #707476;
    font-size: 13px;
}

.modal-backdrop.show {
    opacity: 0.7;
}

.modal-backdrop {
    background: var(--overlay);
}

/************* BPFO Gaming Modal CSS Start ***********/
.bpofgaming-modal {
    .modal-content {
        .modal-header {
            h5 {
                @include text-clip(var(--bpof_text_gradient));
            }

            .btn-close {
                background-color: var(--airdrop_bg_secondary);
                background-image: none;

                em {
                    @include text-clip(var(--bpof_text_gradient));
                }
            }
        }

        .bpof-bg {
            padding: 15px;
            background: var(--bpof_bg_primary);
            border-radius: 14px;
        }

        .btn-default:not(.btn-disable) {
            @include secondarybluebutton;
        }

        .description-text {
            @include text-clip(var(--bpof_text_gradient));
            font-weight: 600;
        }
    }
}

.bpofgaming {
    .btn-default:not(.btn-disable) {
        @include secondarybluebutton;
    }
}

/************* BPFO Gaming Modal CSS End ***********/

/************* ITB Gaming Modal CSS Start ***********/

.intotheblock-modal {
    .modal-content {
        .modal-header {
            h5 {
                @include text-clip(var(--itb_text_primary));
            }

            .btn-close {
                background: rgba(46, 113, 240, 0.08);
                background-image: none;

                em {
                    @include text-clip(var(--itb_text_primary));
                }
            }
        }

        .description-text {
            @include text-clip(var(--itb_text_primary));
            font-weight: 600;
        }

        .itb-bg {
            padding: 15px;
            background-color: var(--bg_secondary4);
            border-radius: 14px;
        }
    }

    .btn-default:not(.btn-disable) {
        &:after {
            background: #2e71f0;
        }

        &:before {
            background: #4374d2;
        }
    }

    .participate-link {
        color: #2e71f0;
    }
}

/************* ITB Gaming Modal CSS End ***********/

/*********** S4F Modal CSS Start *************/
.sense4fit-modal {
    .modal-content {
        .modal-header {
            h5 {
                @include text-clip(var(--text_primary));
            }

            .btn-close {
                background: var(--bg_secondary3);
                background-image: none;

                em {
                    @include text-clip(var(--text_primary));
                }
            }
        }

        .description-text {
            @include text-clip(var(--text_primary));
            font-weight: 600;
        }

        .notes-bg {
            padding: 15px;
            background: var(--bg_secondary3);
            border-radius: 14px;
        }
    }

    .btn-default:not(.btn-disable) {
        &:after {
            background: var(--s4f_gradient_four);
        }

        &:before {
            background: var(--s4f_gradient_primary);
        }
    }

    .participate-link {
        color: #2e71f0;
    }
}
/*********** S4F Modal CSS END *************/
/*********** blockGame Modal CSS Start *************/
.BlockGames-modal {
    .modal-content {
        .modal-header {
            h5 {
                @include text-clip(var(--text_primary));
            }

            .btn-close {
                background: var(--bg_secondary3);
                background-image: none;

                em {
                    @include text-clip(var(--text_primary));
                }
            }
        }

        .description-text {
            @include text-clip(var(--text_primary));
            font-weight: 600;
        }

        .notes-bg {
            padding: 15px;
            background: var(--block_game_modal_bg);
            border-radius: 14px;
        }
    }

    .btn-default:not(.btn-disable) {
        &:after {
            background: var(--block_game_gradient_primary);
        }

        &:before {
            background: var(--block_game_gradient_secondary);
        }
    }

    .participate-link {
        color: #2e71f0;
    }
    &.ixfi-modal .modal-content .btn-default:not(.btn-disable) {
        &:after {
            background: linear-gradient(70deg, #004c5e 13.11%, #29005d 48.77%, #600d9f 86.43%);
        }
        &:before {
            background: linear-gradient(70deg, #600d9f 13.11%, #29005d 48.77%, #004c5e 86.43%);
        }
    }
}
/*********** blockGame Modal CSS END *************/
.btn-default {
    @include buttonDefault;
}

.btn-disable {
    @include buttonDisable;
    background: var(--bg_gray_primary);
    cursor: not-allowed !important;
}

.btn-xs {
    @include btnXSmall;
}

.btn-sm {
    @include btnSmall;
}

.btn-md {
    @include btnMedium;
}

.btn-lg {
    @include btnLarge;
}

.btn-xl {
    @include btnXL;
}

.blue-gradient {
    @include primarybluegradient;
}

.btn-twice-outline {
    @include twiceButtonOutline;
}

.btn-twice-outline-md {
    @include twice-outline-btn-md;
}

.btn-twice-outline-lg {
    @include twice-outline-btn-lg;
}

.btn-twice-outline-xl {
    @include twice-outline-btn-xl;
}

.btn-secondary {
    @include buttonSecondary;
}

.btn-outline {
    @include buttonOutline;

    &.active {
        background: var(--gradient_five);
        color: var(--White);
    }
}

.btn-outline-xs {
    @include buttonOutlineXS;
}

.btn-outline-sm {
    @include buttonOutlinesm;
}

.btn-outline-lg {
    @include buttonOutlineLg;
}

.info-msg {
    .info-box {
        @include flex-center;
        margin-top: 20px;
        margin-bottom: 15px;

        .info-box-inner {
            border-radius: 8px;
            padding: 7px 15px;
            min-width: 290px;
            display: flex;
            @media screen and (max-width: $xs300) {
                min-width: 240px;
                padding: 7px;
            }

            &.green {
                background: var(--green_color_15);

                em {
                    background: var(--green_color);
                }
            }

            &.orange {
                background: var(--orange_color_10);

                em {
                    background: #ffad29;
                }
            }

            em {
                border-radius: 50%;
                padding: 5px 6px;
                font-size: 9px;
                align-content: center;
                @include flex-center;
                color: var(--White);
            }

            span {
                color: var(--text_fifth);
                @include font-500(13px);
                margin-left: 10px;
                @include flex-v-center;
                @media screen and (max-width: $xs300) {
                    font-size: 12px;
                }
            }
        }
    }
}

/*----------------- Vendor css ----------- */
.card-heading {
    font-style: normal;
    @include font-600(20px);
    margin-bottom: 8px;
    @include text-clip(var(--bpof_text_gradient));
}

.intotheblock {
    .card-heading {
        @include text-clip(#2e71f0);
    }
}

.view-all {
    @include font-500(14px);
    color: var(--purple_primary);
    transition-duration: all 0.4s;
    position: relative;
    z-index: 1;
    padding: 5px 0 5px 11px;
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;

    &:hover {
        color: var(--White);
        padding: 5px 11px 5px 11px;
    }
}

a.view-all:after {
    content: '';
    display: block;
    width: 0;
    height: 30px;
    background: var(--gradient_five);
    display: inline-block;
    border-radius: 25px;
    position: absolute;
    transition: all 0.4s;
    right: 0;
    z-index: -1;
}

.view-all:hover::after {
    width: 100%;
}

.view-all {
    @include font-500(14px);
    color: var(--purple_primary);
    transition-duration: all 0.4s;
    position: relative;
    z-index: 1;
    padding: 5px 0 5px 11px;
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;

    &:hover {
        color: var(--White);
        padding: 5px 11px 5px 11px;
    }
}

a.view-all:after {
    content: '';
    display: block;
    width: 0;
    height: 30px;
    background: var(--gradient_five);
    display: inline-block;
    border-radius: 25px;
    position: absolute;
    transition: all 0.4s;
    right: 0;
    z-index: -1;
}

.view-all:hover::after {
    width: 100%;
}

/************* Early Access CSS Start *****************/
.early-access {
    &.blue-bg {
        .modal-content {
            background: linear-gradient(180deg, #974bf8 0%, #340e64 100%), var(--White);

            &::before {
                content: '';
                display: block;
                background: url(https://dfwt0d2sx0pdr.cloudfront.net/reward/early-access/unlock-pattern.png);
                width: 100%;
                height: 100%;
                background-size: 100% 100%;
                position: absolute;
            }
        }
    }

    @media screen and (max-width: $xs420) {
        br {
            display: none;
        }
    }

    .btn-close {
        background-color: var(--bg_primary4);
        background-image: none;

        em {
            @include text-clip(var(--gradient_secondary));
        }
    }
}

/************* Early Access CSS End *****************/

input[type='text'],
input[type='email'],
input[type='date'],
input[type='number'],
input[type='password'] {
    &.ng-invalid.ng-dirty {
        border-color: var(--red_color);
        padding-right: calc(1em + 0.75rem);
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

        &.reset-validation {
            background: none;
            border-color: var(--LightGray-1);
        }
    }

    &.ng-valid.ng-dirty {
        border-color: var(--green_color);
        padding-right: calc(1.5em + 0.75rem);
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

        &.reset-validation {
            background: none;
            border-color: var(--LightGray-1);
        }

        &.mobile-number {
            background-image: none;
        }
    }

    &.reset-validation {
        background: none;
        border-color: var(--LightGray-1);
    }
}

.validation-message {
    color: var(--red_color);
    font-size: 12px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    @include flex-box-column;
    position: relative;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: auto;
}

.shake {
    animation: shake 300ms 0ms 2 both cubic-bezier(0, 0, 0.2, 1);
}

@keyframes shake {
    25% {
        transform: translateX(4px);
    }

    50% {
        transform: translateX(-4px);
    }

    100% {
        transform: translateX(4px);
    }
}

.error-border-bg {
    border: 1px solid var(--red_color);
    background: var(--bg_primary5);
}

//  redeem scss

.claimed-text {
    @include flex-h-center;
    transition: ease all 0.5s;
    margin-bottom: 2px;

    p {
        background: var(--purple_2_20);
        padding: 4px 13px;
        border-radius: 38px;
        @include font-500(10px);
        margin-bottom: 0;
        color: var(--text_secondary) !important;
    }
}

.button-icon {
    background: var(--purple_2_10);
    border-radius: 38px;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;
    color: var(--text_primary);

    &.calim-btn {
        font-size: 12px;
    }

    strong {
        font-weight: 500;
        transition: ease all 0.5s;

        &.point-bigger {
            @include font-600(14px);

            @media screen and (max-width: $extraExtraLarge) {
                font-size: 11px;
            }
        }
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--purple_2_10);
        border-radius: 10rem;
        z-index: -2;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background: var(--gradient_five);
        transition: all 0.4s;
        border-radius: 10rem;
        z-index: -1;
    }

    .next-icon {
        background: var(--purple_primary);
        width: 25px;
        height: 25px;
        border-radius: 25px;
        @include flex-center;
        color: var(--White);
        font-size: 18px;
        padding-left: 2px;

        @media screen and (max-width: $large) {
            font-size: 15px;
            padding-left: 1px;
        }
        @media screen and (max-width: $xs325) {
            font-size: 12px;
        }
    }

    &:hover {
        p {
            color: var(--White);
        }

        strong,
        span {
            color: var(--White);
            transition: ease all 0.5s;
        }

        &:before {
            width: 100%;
        }

        .next-icon {
            animation: moveRight ease-out 1.5s infinite;
        }

        .ixfi-icon {
            background: var(--gradient_six);
            box-shadow: var(--shadow);
        }
    }

    p {
        @include font-400(12px);
        color: var(--black);
        margin-bottom: 0;
        align-self: center;

        &:hover {
            color: var(--White);
        }
    }

    .ixfi-icon {
        background: var(--gradient_six);
        border-radius: 38px;
        margin-right: 5px;
        padding: 4px;

        @media screen and (max-width: $extraExtraLarge) {
            width: 22px !important;
            height: 22px;
            margin-right: 4px;
        }
    }
}

@keyframes moveRight {
    0% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0px);
    }
}

.heading {
    @include text-clip(var(--gradient_five));
    @include font-600(20px);
    display: inline-block;
    margin-bottom: 5px;

    @media screen and (max-width: $medium) {
        font-size: 20px;
    }

    @media screen and (max-width: $small) {
        font-size: 18px;
    }
}

//progress bar
.range {
    width: 100%;
    height: 6px;
    background: var(--purple_2_20);
    border-radius: 5px;
    position: relative;
    transition: ease all 0.5s;
    left: 4px;

    .range-bar {
        display: block;
        height: 100%;
        max-width: 100%;
        background: var(--gradient_primary);
        background-size: 300% 100%;
        animation: progress-animation 2s linear infinite;
        border-radius: 5px;
        transition-duration: 0.5s;
        width: 0%;

        .progress-value {
            position: absolute;
            background: var(--gradient_primary);
            box-shadow: var(--view_shadow_regular_dark_02);
            top: -4px;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            transform: translateX(-50%);
            @media screen and (max-width: $large) {
                width: 12px;
                height: 12px;
            }
            @media screen and (max-width: $xs300) {
                top: -2px;
                width: 10px;
                height: 10px;
            }
        }
    }

    @keyframes zoomIn {
        0% {
            box-shadow: 0px 1px 5px 1px #9d50ff;
        }

        50% {
            box-shadow: 0px 2px 10px 2px #6900f2;
            background-color: red;
        }

        100% {
            box-shadow: 0px 1px 5px 1px #9d50ff;
        }
    }

    @keyframes progress-animation {
        0% {
            background-position: 100%;
        }

        100% {
            background-position: 0;
        }
    }
}

.reword-redeem {
    transition: ease all 0.5s;
    transform: translateX(0px);
    @include font-400(12px);
    color: var(--text_fifth);
    margin-bottom: 15px;

    @media screen and (max-width: $small) {
        font-size: 11px;
    }

    @media screen and (max-width: $xs360) {
        font-size: 10px;
    }
    @media screen and (max-width: 280px) {
        font-size: 9px;
    }
}

.coming-soon {
    p {
        @include font-400(13px);
        color: var(--text_fifth);
        max-width: 830px;

        @media screen and (max-width: $medium) {
            font-size: 12px;
            padding-left: 0px;
        }

        @media screen and (max-width: $small) {
            br {
                display: none;
            }
        }
    }
}

/****************** Start Common CSS *****************/
.shadow-main {
    height: 67%;
    z-index: 1;
    width: 100%;
    position: absolute;
    background: linear-gradient(360deg, #000 0%, #0a070b00 100%);
    border-radius: 15px;
    bottom: 0px;
}

.overlap-text {
    position: absolute;
    bottom: 4%;
    width: 100%;
    z-index: 9;
    flex-direction: column;
    padding: 10px;
    @media screen and (max-width: $xs350) {
        padding: 5px;
    }
    h5 {
        @include font-600(16px);
        color: var(--White);
        text-align: left;

        @media screen and (max-width: $small) {
            text-align: center;
            font-size: 11px;
        }
    }

    .rewards-title {
        @include font-600(16px);
        color: var(--White);
        text-align: left;
        line-height: 1.2;
        margin-bottom: 0.5rem;

        @media screen and (max-width: $small) {
            text-align: center;
            font-size: 11px;
        }
    }

    label {
        color: var(--White);
        @include font-600(14px);
        padding-bottom: 5px;
        @media screen and (max-width: $xs350) {
            font-size: 12px;
        }
    }

    .ixfi-icon {
        background: var(--reward_gradient_blue_purple);
        border-radius: 38px;
        margin-right: 5px;
        width: 18px !important;
        display: inline-block !important;
        height: 18px;
        padding: 3px;
    }

    span {
        color: var(--sub_text_secondary);
        @include font-500(12px);

        @media screen and (max-width: $md1024) {
            font-size: 10px;
        }

        @media screen and (max-width: $large) {
        }
    }

    h6 {
        color: var(--sub_text_secondary);
        @include font-400(10px);
        margin-bottom: 0;
    }

    .responsive-btn-space {
        @media screen and (max-width: $small) {
            width: 110px;
            float: right;
            margin-left: 15px;
        }
    }
}

/****************** End Common CSS *****************/
// Partical js
.container.position-relative {
    z-index: 15;
}

.container-fluid.position-relative {
    z-index: 15;
}

.latest-one {
    .swiper {
        padding-bottom: 35px;

        @media screen and (max-width: $small) {
            padding-bottom: 40px;
        }
    }

    .swiper-pagination-horizontal {
        margin-top: 50px;
        width: 30%;
        bottom: 0px;
        top: auto !important;
        left: 50% !important;
        transform: translateX(-50%);
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: $medium) {
            margin-top: 25px;
        }

        .swiper-pagination-progressbar-fill {
            background: var(--purple_primary) !important;
        }
    }
}

.card__collection {
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 15px;

    .overlap-text {
        transition: ease 0.2s all;
    }

    &:hover {
        .overlap-text {
            transition: ease 0.2s all;
        }
    }

    .cards {
        width: 100%;
        display: block;
        background-size: cover;
        float: left;
        border-radius: 15px;
        position: relative;
        overflow: hidden;
        background-position: center;
    }

    .cards--two {
        position: relative;
        backface-visibility: hidden;

        h2 {
            position: absolute;
            top: 52%;
            right: -100%;
            text-transform: capitalize;
            color: var(--White);
            font-size: 20px;
            z-index: 8;
            transition: all 0.6s ease;

            @media screen and (max-width: 1600px) {
                top: 51%;
            }
        }

        h4 {
            position: absolute;
            bottom: 10%;
            left: -100%;
            text-transform: capitalize;
            color: var(--White);
            font-size: 18px;
            z-index: 8;
            transition: all 0.6s ease;
            text-align: left;

            @media screen and (max-width: 1600px) {
                font-size: 16px;
            }

            @media screen and (max-width: $small) {
                bottom: 15%;
                font-size: 14px;
            }
        }

        img {
            transform: scale(1) translateY(0px);
            transition: ease all 0.5s;
        }

        &:hover {
            img.img-fluid {
                transform: scale(1.2) translateY(-30px);
                transition: ease all 0.5s;
            }
        }
    }
}

div.swiper-pagination-progressbar {
    background: var(--purple_color_30);
}

.swiper {
    width: 100%;
    height: 100%;
    @include flex-v-center;
    flex-direction: column;

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

        img {
            display: block;
            width: 100%;
            height: auto;
            object-fit: cover;
            box-shadow: none !important;
        }
    }

    .swiper-button-next {
        padding: 23px;
        color: var(--text_fifth);
        width: 35px;
        left: auto;
        right: 0px;
        background: var(--slide-gradient-next);
        font-weight: 900;

        &::after {
            font-size: 22px;

            @media screen and (max-width: $xs400) {
                font-size: 18px;
            }
        }

        height: calc(100% - 40px);
        top: 38px;
    }

    .swiper-button-prev {
        padding: 23px;
        color: var(--text_fifth);
        width: 35px;
        right: auto;
        left: 0px;
        background: var(--slide-gradient-prev);
        height: calc(100% - 32px);
        top: 0px;
        font-weight: 900;

        &::after {
            font-size: 22px;

            @media screen and (max-width: $xs400) {
                font-size: 18px;
            }
        }
    }
}

.featured-section {
    &.swiper {
        .swiper-pagination-horizontal {
            margin-top: 50px;
            width: 30% !important;

            @media screen and (max-width: $medium) {
                margin-top: 25px;
            }

            .swiper-pagination-progressbar-fill {
                background: var(--purple_primary) !important;
            }
        }
    }
}

.border-b-1 {
    border-bottom: solid 1px var(--gray_divider3);
}

// redeem detail page styles

.product-main {
    .detail-main {
        .detail-area {
            background: var(--bg_primary5);
            border: 1px solid var(--gray_divider3);
            border-radius: 28px;
            margin-top: -250px;
            max-width: 1000px;
            margin-left: auto;
            margin-right: auto;
            overflow: hidden;

            .back-list {
                z-index: 2;
                position: absolute;
                top: -1px;
                left: 0px;
                border-radius: 28px 0px 0px 0px;
                width: 100%;
                transition: all 0.3s;
                background: linear-gradient(180deg, black, transparent);
                padding: 15px 10px;

                @media (max-width: $width767) {
                    border-radius: 28px 28px 0px 0px;
                }

                a {
                    font-family: 'Poppins', sans-serif;
                    font-style: normal;
                    @include font-500(15px);
                    display: flex;
                    padding: 5px;
                    align-items: baseline;
                    align-content: center;
                    color: var(--White);

                    &:hover {
                        span {
                            text-decoration: underline;
                        }
                    }
                }

                em {
                    font-size: 11px;
                    padding-right: 8px;
                }

                &:hover {
                    .arrow-effect {
                        animation: moveRight ease-out 1.5s infinite;
                    }
                }
            }

            @keyframes moveRight {
                0% {
                    transform: translateX(0px);
                }

                50% {
                    transform: translateX(5px);
                }

                100% {
                    transform: translateX(0px);
                }
            }

            .left-area {
                height: 100%;
                background-size: cover;
                border-radius: 26px 0 0 26px;
                @include flex-box-column;

                @media screen and (max-width: $large) {
                    border-radius: 26px 26px 0px 0px;
                }

                img {
                    width: 322px;
                    height: 322px;

                    @media screen and (max-width: $medium) {
                        width: 180px;
                        height: 180px;
                    }
                }

                .shadow-bg {
                    background: linear-gradient(286.35deg, #818af9 -2.4%, rgba(129, 138, 249, 0) 148.74%);
                    overflow: hidden;
                    width: 594px;
                    height: 600px;
                    border-radius: 50%;
                    position: absolute;
                    bottom: 45%;
                    left: 30%;
                    right: 0;
                    transform: translate(-50%, 50%);
                }
            }

            .right-area {
                padding: 20px 40px 20px 20px;
                font-size: 14px;
                width: 100%;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                @media screen and (max-width: $large) {
                    padding: 5px 12px;
                }
                @media screen and (max-width: $width767) {
                    padding: 20px;
                }

                .redeem-btn {
                    @include flex-h-center;

                    @media screen and (max-width: $small) {
                        flex-direction: column;
                    }

                    .unlock-btn {
                        margin-left: 20px;

                        @media screen and (max-width: $small) {
                            margin-left: 0;
                            margin-top: 15px;
                        }
                    }

                    .button-default {
                        background-image: linear-gradient(
                            66.69deg,
                            #373ecc -3.61%,
                            #aa44e4 24.87%,
                            #fa6f75 58.49%,
                            #fda154 83.71%
                        );
                        background-size: 100% 100%;
                        transition: all 0.4s ease-in-out;
                        padding: 10px 60px;
                        height: 45px;
                        display: inline-block;
                        font-family: 'Poppins', sans-serif;
                        @include font-500(00.875rem);
                        font-style: normal;
                        line-height: 25px;
                        letter-spacing: 0px;
                        text-align: center;
                        border-radius: 50px;
                        border: 0px;
                        color: var(--White);
                        margin-top: 3px;

                        @media screen and (max-width: $large) {
                            margin-left: 3%;
                        }

                        @media screen and (max-width: $small) {
                            font-size: 12px;
                            padding: 10px 25px !important;
                        }

                        &:hover {
                            background-image: linear-gradient(61.33deg, #fda154 4.78%, #fa6f75 68.57%, #aa44e4 136.47%);
                        }
                    }
                }

                .top-text {
                    .badge {
                        @include font-600(9px);
                        line-height: 12px;
                        letter-spacing: 0.08em;
                        background: var(--purple_primary);
                        text-transform: uppercase;
                    }

                    h2 {
                        font-family: 'Poppins', sans-serif;
                        @include font-600(28px);
                        line-height: 35px;
                        color: var(--black);
                        padding-top: 6px;
                        margin-bottom: 4px;
                        color: var(--text_fifth);

                        @media screen and (max-width: $large) {
                            font-size: 22px;
                            line-height: 1.2;
                        }
                    }

                    p {
                        font-family: 'Poppins', sans-serif;
                        @include font-400(16px);
                        line-height: 22px;
                        color: var(--text_fifth);
                        margin-bottom: 0px;

                        @media screen and (max-width: $large) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .prog-bar {
            p {
                color: var(--text_fifth) !important;
                @media screen and (max-width: $large) {
                    font-size: 12px;
                }
            }

            .claimed-text {
                p {
                    background: var(--bg_secondary3);
                }
            }
        }

        .qty {
            @include flex-v-center;
            flex-direction: row;
            margin-bottom: 20px;

            .qty-area {
                background: var(--secondary_bg);
                padding: 4px;
                border-radius: 8px;
                @include flex-center;
                align-content: center;
            }

            @media screen and (max-width: $large) {
                justify-content: center;
            }

            @media screen and (max-width: $small) {
                margin-bottom: 15px;
            }

            @media screen and (max-width: $xs320) {
                justify-content: normal;
            }

            span {
                color: var(--text_fifth);
                @include font-600(14px);
                margin-right: 21px;
            }

            em {
                font-size: 12px;
                padding: 6px 8px;
                cursor: pointer;
                color: var(--text_primary);
                background: var(--bg_primary5);
                height: 28px;
                border-radius: 7px;
                display: flex;
                align-items: center;

                &:hover {
                    color: var(--White);
                    background-color: var(--purple_primary);
                }

                &.no-click-btn {
                    opacity: 0.5;
                    cursor: no-drop;
                    &:hover {
                        color: var(--text_primary);
                        background: var(--bg_primary5);
                    }
                }
            }

            input {
                border: none;
                width: 55px;
                text-align: center;
                background: none;
                border-radius: 0;
                @include font-500(15px);
                color: var(--text_fifth);

                &:focus-visible {
                    outline: none;
                }
            }
        }

        .claim-area {
            background: var(--bg_primary4);
            border-radius: 50px;
            padding: 15px;
            box-shadow: var(--view_shadow_regular_dark_01);
            display: flex;
            justify-content: center;
            flex-direction: column;
            @media screen and (max-width: $large) {
                margin: 2px 0;
                padding: 8px;
            }
            @media screen and (max-width: $width767) {
                position: initial;
                margin: 15px 0;
                padding: 15px;
            }

            @media screen and (max-width: $xs400) {
                padding: 8px;
            }
            @media screen and (max-width: $xs300) {
                padding: 6px;
            }
            .total-point {
                @include flex-v-center;
                flex-direction: row;
                padding-left: 2px;

                @media screen and (max-width: $large) {
                    justify-content: center;
                }

                img {
                    background: var(--reward_gradient_blue_purple);
                    border-radius: 50%;
                    padding: 7px;
                    width: 42px;
                    height: 42px;

                    @media screen and (max-width: $large) {
                        width: 32px;
                        height: 32px;
                    }
                    @media screen and (max-width: $width767) {
                        width: 40px;
                        height: 40px;
                    }
                    @media screen and (max-width: $xs400) {
                        padding: 3px;
                        width: 20px;
                        height: 20px;
                    }
                }

                .total-text {
                    padding-left: 15px;

                    @media screen and (max-width: $xs400) {
                        padding-left: 6px;
                    }

                    h3 {
                        font-family: 'Roboto', sans-serif;
                        font-style: normal;
                        @include font-400(13px);
                        color: var(--sub_text_primary);
                        margin-bottom: 3px;

                        @media screen and (max-width: $xs400) {
                            font-size: 10px;
                        }
                    }

                    h1 {
                        font-family: 'Roboto', sans-serif;
                        font-style: normal;
                        @include font-700(15px);
                        color: var(--text_primary);
                        margin-bottom: 0;

                        @media screen and (max-width: $large) {
                            @include font-500(13px);
                        }
                        @media screen and (max-width: $xs400) {
                            @include font-500(11px);
                        }
                    }
                }
            }

            .point-no-bg {
                img {
                    background: none;
                    border: 1px solid #ddddeb;
                    padding: 3px;
                }
            }
        }

        .detail-description {
            p {
                @include font-400(13px);
                color: var(--text_fifth);
                line-height: 22px;
            }
        }

        .content-area {
            background: var(--bg_primary5);
            border: 1px solid var(--gray_divider3);
            box-shadow: var(--view_shadow_regular_dark_01);
            border-radius: 20px;
            padding: 35px;

            @media screen and (max-width: $medium) {
                padding: 28px 28px 28px 28px;
            }

            @media screen and (max-width: $small) {
                padding: 20px 13px 20px 13px;
            }

            h4 {
                font-style: normal;
                display: initial;
                @include font-600(17px);
                @include text-clip(var(--gradient_primary));
            }

            ul {
                // padding-left: 0;

                // margin-top: 8px;
            }

            li {
                color: var(--text_fifth);
                @include font-400(13px);
                line-height: 24px;
                // @include flex-v-center;
                // list-style: none;

                em {
                    padding-top: 6px;
                    padding-right: 10px;
                    font-size: 18px;
                    color: var(--green_color);
                }
            }
        }

        .task-section {
            box-shadow: none;
            transition: ease all 0.5s;

            &:hover {
                transform: translateY(-5px);
                transition: ease all 0.5s;
            }

            @media screen and (max-width: $small) {
                padding-left: 3px;
                padding-right: 3px;
            }

            @media screen and (max-width: 345px) {
                width: 100%;
            }
        }

        .task-card {
            // margin-right: 30% !important;

            // @media screen and (max-width: $extraLarge) {
            //     margin-right: 0 !important;
            // }

            // @media screen and (max-width: 380px) {
            //     .small-width {
            //         width: 100%;
            //     }
            // }

            .user-icon-card {
                width: 50px;
                margin: 0px auto;
                border: solid 3px transparent;
                border-radius: 50px;
                margin-bottom: 20px;
            }

            .lighter-blue-card {
                .user-icon-card {
                    border-color: rgba(0, 56, 171, 0.3);
                }
            }

            .light-red-card {
                .user-icon-card {
                    border-color: rgba(255, 29, 205, 0.3);
                }
            }

            .dark-bg {
                background: #797979bf;
            }

            .no-bg {
                background: none !important;
            }

            .task-area {
                padding: 18px 18px 12px 18px;
                border-radius: 14px;

                .user-area {
                    @include flex-row;
                    justify-content: space-evenly;

                    .task-user-img {
                        width: 50px;
                        border: solid 4px #00000054;
                        border-radius: 50px;
                    }

                    em {
                        right: 8%;
                        top: 5%;
                        color: var(--White);
                        padding: 7px 7px;
                        border-radius: 50px;
                        background: var(--green_color);
                        font-size: 7px;
                        height: 20px;
                        width: 20px;
                        position: absolute;
                        line-height: 0.2;
                    }
                }

                h4 {
                    @include font-600(13px);
                    line-height: 1.4;
                    height: 50px;
                    margin-top: 20px;
                    text-align: center;
                    color: var(--text_primary);
                }

                .task-text {
                    color: var(--White);
                    @include font-600(13px);
                    line-height: 1.4;
                    height: 50px;
                    margin-top: 20px;
                    text-align: center;
                }

                .task-bottom-area {
                    z-index: 9;

                    .taskbtn {
                        background: var(--reward_gradient_blue_purple);
                        border-radius: 20px;
                        color: var(--White);
                        border: 0px;
                        @include font-500(12px);
                        padding: 4px 28px;
                        height: 30px;

                        @media screen and (max-width: $small) {
                            padding: 4px 20px;
                        }
                    }

                    .task-point {
                        @include flex-v-center;

                        img {
                            width: 20px;
                        }

                        label {
                            color: var(--White);
                            @include font-600(15px);
                            padding-left: 4px;
                            padding-right: 7px;

                            @media screen and (max-width: 357px) {
                                font-size: 12px;
                            }
                        }

                        .points-test {
                            color: var(--text_primary);
                            @include font-600(15px);
                            padding-left: 4px;

                            @media screen and (max-width: 357px) {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    .earned-bg {
        background:
            url('https://dfwt0d2sx0pdr.cloudfront.net/reward/task-center/earn-bg.png') no-repeat,
            var(--secondary_bgy);
        background-size: cover;

        .swiper-button-next {
            height: calc(100% + 2px);
            top: 20px;

            @media screen and (max-width: $medium) {
                height: calc(100% - 6px);
            }
        }

        .swiper-button-prev {
            height: calc(100% + 2px);
            top: 20px;

            @media screen and (max-width: $medium) {
                height: calc(100% - 6px);
            }
        }

        .view-all {
            color: var(--sub_text_primary2);
            @include font-500(16px);

            @media screen and (max-width: $medium) {
                font-size: 13px;
            }

            @media screen and (max-width: $small) {
                padding-top: 15px;
            }

            em {
                color: #b6b7c2;
                font-size: 12px;
                padding-left: 5px;
                padding-right: 5px;

                @media screen and (max-width: $medium) {
                    font-size: 10px;
                }
            }

            &:hover,
            &:hover em {
                color: var(--purple_primary);
            }
        }

        .earned-silder {
            .earnedImg {
                overflow: hidden;
                border-radius: 15px;

                img {
                    transform: scale(1) translateY(0px);
                    transition: ease all 0.5s;
                }
            }

            &:hover {
                .earnedImg {
                    overflow: hidden;

                    img {
                        transform: scale(1.2) translateY(-30px);
                        transition: ease all 0.5s;
                    }
                }
            }
        }

        .earned-check {
            position: absolute;
            z-index: 9;
            display: flex;
            top: 25px;
            right: 21px;

            @media screen and (max-width: $width767) {
                top: 13px;
                right: 13px;
            }

            em {
                color: var(--White);
                padding: 7px 7px;
                border-radius: 50px;
                background: var(--green_color);
                font-size: 7px;
                height: 20px;
                width: 20px;
                line-height: 0.2;
            }
        }

        .overlap-text {
            label {
                color: var(--White);
                @include font-400(16px);
                padding-bottom: 5px;

                @media screen and (max-width: $width767) {
                    line-height: 1.2;
                    font-size: 12px;
                }
            }
        }
    }

    .deck-bg {
        background: var(--secondary_bg_60);

        .ongoing-section {
            &.swiper {
                .swiper-button-prev,
                .swiper-button-next {
                    height: calc(100% - 44px);
                    top: 40px;

                    @media screen and (max-width: $medium) {
                        height: calc(100% - 49px);
                        top: 46px;
                    }

                    @media screen and (max-width: 600px) {
                        height: calc(100% - 40px);
                        top: 43px;
                    }
                }
            }
        }
    }

    .reward-bg {
        .view-all {
            @media screen and (max-width: $medium) {
                font-size: 13px;
            }

            @media screen and (max-width: $small) {
                padding-top: 15px;
            }

            em {
                @media screen and (max-width: $medium) {
                    font-size: 10px;
                }
            }
        }
    }
}

.video-static-height {
    position: relative;
    // width: 60%;
    // top: 0px;
    max-width: 92%;
    z-index: 5;
    // height: 600px;
    margin: 0 auto;
    display: block;
    // @media screen and (max-width: $extraLarge) {
    //     height: 500px;
    // }
    // @media screen and (max-width: $large) {
    //     width: 53%;
    //     left: -17px;
    // }

    // @media screen and (max-width: $small) {
    //     width: 100%;
    //     left: 0px;
    // }
}

/*********** Fixed height CSS Start ***********/

.card-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .card-header-content,
    .card-footer-content {
        flex-shrink: 0;
    }

    .card-title-content {
        flex-grow: 0;
        flex-shrink: 0;
    }

    .card-body-content {
        flex-grow: 1;
    }
}

/*********** Fixed height CSS End ***********/

.intercom-lightweight-app {
    z-index: 99 !important;
}
/********** Preloader CSS Start ***********/
.waviy {
    position: relative;
    -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
    font-size: 12px;
    span {
        font-family: 'arial', cursive;
        position: relative;
        display: inline-block;
        color: var(--orange_color);
        animation: waviy 1s infinite;

        &:nth-child(1) {
            animation-delay: 0.1s;
        }
        &:nth-child(2) {
            animation-delay: 0.2s;
        }
        &:nth-child(3) {
            animation-delay: 0.3s;
        }
        &:nth-child(4) {
            animation-delay: 0.4s;
        }
        &:nth-child(5) {
            animation-delay: 0.5s;
        }
        &:nth-child(6) {
            animation-delay: 0.6s;
        }
        &:nth-child(7) {
            animation-delay: 0.7s;
        }
        &:nth-child(8) {
            animation-delay: 0.8s;
        }
        &:nth-child(9) {
            animation-delay: 0.9s;
        }
        &:nth-child(10) {
            animation-delay: 1s;
        }
    }
}

@keyframes waviy {
    0%,
    50%,
    100% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(-2px);
    }
}
/********** Preloader CSS End ***********/

.skeleton-bg {
    .loader {
        background: #eff1f6 !important;
        margin-bottom: 0px !important;
    }
}
.darkTheme .skeleton-bg {
    .loader {
        background: rgb(41 41 43) !important;
    }
}
.card-body {
    @media screen and (max-width: $xs300) {
        padding: 8px;
    }
}
/************** For Task Center Vendor banner CSS *********/
.task-banner {
    .banner-swipelux {
        .banner-patent {
            margin-bottom: 20px !important;
        }
    }
}
.darkTheme .tooltip {
    .tooltip-inner {
        background-color: var(--bg_popup);
        color: var(--text_secondary);
        // border: 1px solid var(--bg_popup);
    }

    .tooltip-arrow::before {
        border-top-color: var(--bg_popup);
    }
    .tooltip-arrow::after {
        // border-top-color: var(--bg_popup);
    }
}
.skl-200 {
    width: 200px;
}
.skl-w-80 {
    width: 100%;
    height: 20px;
    background: var(--bg_skeleton);
}
.skl-sq {
    height: 70px !important;
    width: 50%;
    background: var(--bg_skeleton);
    span {
        height: 70px !important;
    }
}
.card-skel {
    span {
        height: 210px !important;
        background: var(--bg_skeleton) !important;
        border-radius: 15px !important;
    }
}
.skl-line {
    .loader {
        height: 25px !important;
        background: var(--bg_skeleton) !important;
    }
}
.skl-btn {
    .loader {
        height: 40px !important;
        width: 130px !important;
        background: var(--bg_skeleton) !important;
    }
}
.w-30 {
    .loader {
        width: 150px !important;
    }
}
.sw-50 {
    .loader {
        max-width: 300px !important;
    }
}
.skl-circle {
    .loader {
        height: 100px !important;
        width: 100px !important;
        border-radius: 100px !important;
        background: var(--bg_skeleton) !important;
    }
}
.skeleton-feature-card {
    width: 307px !important;

    @media screen and (max-width: $extraExtraLarge) {
        width: 290px !important;
    }
    @media screen and (max-width: $large) {
        width: 220px !important;
    }
    @media screen and (max-width: $xs425) {
        width: 150px !important;
    }
    .loader {
        height: 158px !important;
        background: var(--bg_skeleton) !important;
        border-radius: 15px !important;
    }
}
.skl-bg {
    .loader {
        background: var(--bg_secondary2) !important;
    }
}
.card {
    .skl-card-item {
        .loader {
            height: 184px;
            width: 204px;
            background: var(--bg_skeleton);
            border-radius: 15px;
        }
    }
    .skl-card-line {
        .loader {
            height: 20px;
            width: 150px;
            background: var(--bg_skeleton);
            border-radius: 15px;
        }
    }
    .skl-card-line-small {
        .loader {
            height: 20px;
            width: 100px;
            background: var(--bg_skeleton);
            border-radius: 15px;
        }
    }
    .skl-range {
        .loader {
            height: 10px;
            width: 196px;
            background: var(--bg_skeleton);
            border-radius: 15px;
        }
    }
    .skl-card-btn {
        .loader {
            height: 50px;
            width: 200px;
            background: var(--bg_skeleton);
            border-radius: 50px;
        }
    }
}

.bober-modal {
    .modal-content {
        .modal-header {
            h5 {
                @include text-clip(var(--text_primary));
            }

            .btn-close {
                background: var(--bg_secondary3);
                background-image: none;

                em {
                    @include text-clip(var(--text_primary));
                }
            }
        }

        .description-text {
            @include text-clip(var(--text_primary));
            font-weight: 600;
        }

        .notes-bg {
            padding: 15px;
            background: var(--block_game_modal_bg);
            border-radius: 14px;
        }
    }
    .btn-default:not(.btn-disable) {
        &:after {
            background: var(--bober_gradient_primary);
        }
        &:before {
            background: var(--bober_gradient_secondary);
        }
    }
    .participate-link {
        color: #2e71f0;
    }
    &.ixfi-modal .modal-content .btn-default:not(.btn-disable) {
        &:after {
            background: var(--bober_gradient_primary);
        }
        &:before {
            background: var(--bober_gradient_secondary);
        }
    }
}

.ta-da-modal {
    .modal-content {
        .modal-header {
            h5 {
                @include text-clip(var(--text_primary));
            }

            .btn-close {
                background: var(--bg_secondary3);
                background-image: none;

                em {
                    @include text-clip(var(--text_primary));
                }
            }
        }

        .description-text {
            @include text-clip(var(--text_primary));
            font-weight: 600;
        }

        .notes-bg {
            padding: 15px;
            background: var(--block_game_modal_bg);
            border-radius: 14px;
        }
    }
    .btn-default:not(.btn-disable) {
        &:after {
            background: var(--taDa_gradient_primary);
        }
        &:before {
            background: var(--taDa_gradient_secondary);
        }
    }
    .participate-link {
        color: #2e71f0;
    }
    &.ixfi-modal .modal-content .btn-default:not(.btn-disable) {
        &:after {
            background: var(--taDa_gradient_primary);
        }
        &:before {
            background: var(--taDa_gradient_secondary);
        }
    }
}

/*------------- Twitter Farming -----------*/
.twitter-card-title {
    h2 {
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.5px;
        @include flex-v-center;
        justify-content: center;
        text-align: center;
        line-height: 1.2;
        @media screen and (max-width: $medium) {
        }
        @media screen and (max-width: $small) {
            font-size: 18px;
        }
        @media screen and (max-width: $small) {
            font-size: 16px;
        }
        span {
            background: var(--gradient_primary);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding: 0px 5px;
        }
    }
    p {
        color: var(--text_fifth);
        text-align: center;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
        @media screen and (max-width: $small) {
            font-size: 12px;
            margin-bottom: 10px;
        }
    }
}
:root {
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
}
/*--------------- Animation for ixfi -----------*/
[data-ixfi-animation='fade-up'],
[data-ixfi-animation='fade-right'],
[data-ixfi-animation='fade-left'] {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

[data-ixfi-animation='fade-up'].Animate {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}
@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 50px, 0);
        transform: translate3d(0, 50px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 50px, 0);
        transform: translate3d(0, 50px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
[data-ixfi-animation='fade-right'].Animate {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(50px, 0, 0);
        transform: translate3d(50px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(50px, 0, 0);
        transform: translate3d(50px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
[data-ixfi-animation='fade-left'].Animate {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-50px, 0, 0);
        transform: translate3d(-50px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-50px, 0, 0);
        transform: translate3d(-50px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
.without-swiper {
    display: flex;
    padding: 0 20px;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    @media screen and (max-width: $medium) {
        padding: 0 40px;
        gap: 20px;
    }
    @media screen and (max-width: $small) {
        padding: 0 20px;
        gap: 10px;
    }
    .inner-area {
        width: 50%;
    }
}
.container {
    @media screen and (max-width: $large) and (min-width: 820px) {
        max-width: calc(100% - 30px);
    }
}
