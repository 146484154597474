/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';
@import './assets/css/variable';
@import './assets/css/style.scss';
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';

// /* Importing Datepicker SCSS file. */
@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker';
