body {
    /*------------------------ Same color in  in dark mode ----------------------*/
    --purple_primary: #8b37f7;
    --pink_secondary: #fd66b5;
    --orange_third: #ff9e00;
    --neon_yellow: #ff9e00;
    --neon_green: #03ffa3;
    --neon_sky: #01fff4;
    --neon_pink: #ff1178;

    --icon_gray: #686171;

    // --purple_2: #8B80DE;
    --purple_color-dark: #1f0735;
    --footer_gray: #2d2d2d;
    --White: #ffffff;

    --black: #000000; //added for --black & --black-primary

    --purple_color: #8b37f7;
    --purple_color_90: rgba(139, 55, 247, 0.9);
    --purple_color_80: rgba(139, 55, 247, 0.8);
    --purple_color_70: rgba(139, 55, 247, 0.7);
    --purple_color_60: rgba(139, 55, 247, 0.6);
    --purple_color_50: rgba(139, 55, 247, 0.5);
    --purple_color_40: rgba(139, 55, 247, 0.4);
    --purple_color_30: rgba(139, 55, 247, 0.3);
    --purple_color_20: rgba(139, 55, 247, 0.2);
    --purple_color_10: rgba(139, 55, 247, 0.1);
    --purple_color_light: #fbf1ff; //without opacity

    --pink_color: #fd66b5;
    --pink_color_90: rgba(253, 102, 181, 0.9);
    --pink_color_80: rgba(253, 102, 181, 0.8);
    --pink_color_70: rgba(253, 102, 181, 0.7);
    --pink_color_60: rgba(253, 102, 181, 0.6);
    --pink_color_50: rgba(253, 102, 181, 0.5);
    --pink_color_40: rgba(253, 102, 181, 0.4);
    --pink_color_30: rgba(253, 102, 181, 0.3);
    --pink_color_20: rgba(253, 102, 181, 0.2);
    --pink_color_10: rgba(253, 102, 181, 0.1);

    --orange_color: #ff9e00;
    --orange_color_90: rgba(255, 158, 0, 0.9);
    --orange_color_80: rgba(255, 158, 0, 0.8);
    --orange_color_70: rgba(255, 158, 0, 0.7);
    --orange_color_60: rgba(255, 158, 0, 0.6);
    --orange_color_50: rgba(255, 158, 0, 0.5);
    --orange_color_40: rgba(255, 158, 0, 0.4);
    --orange_color_30: rgba(255, 158, 0, 0.3);
    --orange_color_20: rgba(255, 158, 0, 0.2);
    --orange_color_10: rgba(255, 158, 0, 0.1);

    --purple_2: #8b80de;
    --purple_2_90: rgba(139, 128, 222, 0.9);
    --purple_2_80: rgba(139, 128, 222, 0.8);
    --purple_2_70: rgba(139, 128, 222, 0.7);
    --purple_2_60: rgba(139, 128, 222, 0.6);
    --purple_2_50: rgba(139, 128, 222, 0.5);
    --purple_2_40: rgba(139, 128, 222, 0.4);
    --purple_2_30: rgba(139, 128, 222, 0.3);
    --purple_2_20: rgba(139, 128, 222, 0.2);
    --purple_2_10: rgba(139, 128, 222, 0.1);

    /* --------- Text Color  -------- */
    --text_primary: #000619;
    --text_secondary: #212529;
    --text_third: #2d2d2d;

    --text_fifth: #000000;

    --sub_text_primary: #707476;
    --sub_text_primary2: #979da0;
    --sub_text_secondary: #d9d9d9; // --gray-primary
    --sub_text_secondary2: #d3d3d3;
    --sub_text_secondary3: #707476;
    --text-purple-reverse: #8b80de; //added by html team
    --purple_2_reverse: #f1efff;
    //--gray_dark: #4b4b4b; // --gray-secondary  --gray-three

    /*------------ Divider Color --------------*/
    --gray_divider1: #a0a7b8;
    --gray_divider2: #c9d6df; //replace with var(--border-primary)
    --gray_divider3: #eaeaea;
    --gray_divider4: #eeeeee;
    --gray_divider5: #f3f3f3;

    /*-------------- Other Color -------------------*/
    --green_color: #16b68e;
    --green_color_60: rgba(22, 182, 142, 0.6);
    --green_color_30: rgba(22, 182, 142, 0.3);
    --green_color_15: rgba(22, 182, 142, 0.15);
    --green_color_06: rgba(22, 182, 142, 0.06); //added

    --red_color: #e25638;
    --red_color_10: rgba(226, 86, 56, 0.1);
    --red_color_60: rgba(226, 86, 56, 0.6);
    --red_color_30: rgba(226, 86, 56, 0.3);
    --red_color_15: rgba(226, 86, 56, 0.15);

    /* -- xxxxxxxxxxxxxxx not added in style guide xxxxxxxxxxxxxxxxxxxxxxxxx -- */
    --blue_shadow: #4623e9;
    --blue_shadow_60: rgba(70, 35, 233, 0.6);
    --blue_shadow_30: rgba(70, 35, 233, 0.3);
    --blue_shadow_15: rgba(70, 35, 233, 0.15);

    --water_blue_color: #09a8da;
    --water_blue_color_60: rgba(9, 168, 218, 0.6);
    --water_blue_color_30: rgba(9, 168, 218, 0.3);
    --water_blue_color_15: rgba(9, 168, 218, 0.15);

    /* -- xxxxxxxxxxxxxxx not added in style guide xxxxxxxxxxxxxxxxxxxxxxxxx -- */
    --blue_shadow: #3461ff;
    --blue_shadow_60: rgba(52, 97, 255, 0.6);
    --blue_shadow_30: rgba(52, 97, 255, 0.6);
    --blue_shadow_15: rgba(52, 97, 255, 0.6);
    /*-------------------   End Same color in  in dark mode ------------------------*/

    --primary_bg_100: #f2f5fa;
    --primary_bg_60: rgba(242, 245, 250, 0.6);
    --primary_bg_30: rgba(242, 245, 250, 0.3);

    --secondary_bg: #e6eaf5;
    --secondary_bg_60: rgba(230, 234, 245, 0.6);
    --secondary_bg_30: rgba(230, 234, 245, 0.3);

    --third_bg: #f9f9f9;
    --third_bg_60: rgba(249, 249, 249, 0.6);
    --third_bg_30: rgba(249, 249, 249, 0.3);

    --fourth_bg: #f2f2f2;
    --fourth_bg_60: rgba(242, 242, 242, 0.6);
    --fourth_bg_30: rgba(242, 242, 242, 0.3);

    --fifth_bg: #f2f2f2;
    --bg_primary: #f2f5fa;
    --bg_primary2: #ffffff;
    --bg_primary3: #ffffff;
    --bg_primary4: #ffffff;
    --bg_primary5: #ffffff;
    --bg_skeleton: #eff1f6;

    --bg_main_card_body: #f8fafb;

    --bg_secondary: rgba(242, 245, 250, 0.8);
    --bg_secondary2: rgba(139, 128, 222, 0.1);
    --bg_secondary3: rgba(139, 55, 247, 0.06);
    --bg_secondary4: #eff2f5;
    --bg_secondary5: #8b37f7;
    --bg-transparent: #fff;

    //--card-gray-transparent: rgba(255, 255, 255, 0.5);
    --bg_gray_primary: #a5a5a5; // only used for disable button

    --gradient_primary: linear-gradient(74.61deg, #8b37f7 14.74%, #c754bc 43.89%, #fb7870 66.64%, #fda154 83.75%);
    --gradient_primary_reverse: linear-gradient(229deg, #8b37f7 14.74%, #c754bc 43.89%, #fb7870 66.64%, #fda154 83.75%);
    --gradient_secondary: linear-gradient(70.43deg, #4318bc 13.11%, #7222c9 86.43%);
    --gradient_third: linear-gradient(70.43deg, #8b37f7 13.11%, #6b12dd 86.43%);
    --gradient_forth: linear-gradient(107.91deg, #eaabf0 7.37%, #4623e9 95.19%);
    --gradient_five: linear-gradient(70.43deg, #373ecc 13.11%, #7441d9 53.11%, #aa44e4 86.43%);
    --gradient_five_reverse: linear-gradient(244deg, #373ecc 25.27%, #aa44e4 86.74%);
    --gradient_six: linear-gradient(92.51deg, #010002 3.3%, #460c91 58.8%, #3c008a 93.12%);
    --gradient_seven: linear-gradient(90deg, #8f49ff -0.01%, #a872ff 100%);
    --gradient_eight_50: linear-gradient(
        185.63deg,
        rgba(113, 54, 205, 0.5) -17.15%,
        rgba(253, 243, 242, 0.5) 0.73%,
        rgba(218, 47, 111, 0.5) 39.89%,
        rgba(40, 81, 166, 0.5) 91.46%,
        rgba(253, 243, 242, 0.5) 91.46%,
        rgba(253, 243, 242, 0.5) 91.46%,
        rgba(172, 125, 48, 0.5) 91.46%
    );
    --gradient_eight_20: linear-gradient(
        185.63deg,
        rgba(113, 54, 205, 0.075) -17.15%,
        rgba(253, 243, 242, 0.075) 0.73%,
        rgba(218, 47, 111, 0.075) 39.89%,
        rgba(40, 81, 166, 0.075) 91.46%,
        rgba(253, 243, 242, 0.075) 91.46%,
        rgba(253, 243, 242, 0.075) 91.46%,
        rgba(172, 125, 48, 0.075) 91.46%
    );
    --gradient_eight_10: linear-gradient(
        185.63deg,
        rgba(113, 54, 205, 0.05) -17.15%,
        rgba(253, 243, 242, 0.05) 0.73%,
        rgba(218, 47, 111, 0.05) 39.89%,
        rgba(40, 81, 166, 0.05) 91.46%,
        rgba(253, 243, 242, 0.05) 91.46%,
        rgba(253, 243, 242, 0.05) 91.46%,
        rgba(172, 125, 48, 0.05) 91.46%
    );
    --gradient_nine: linear-gradient(180deg, rgba(139, 128, 222, 0.37) 23.64%, rgba(255, 255, 255, 0) 105.61%);
    --gradient_ten: linear-gradient(
        248.52deg,
        rgba(225, 202, 255, 0.5) 7.06%,
        rgba(230, 210, 249, 0.8) 22.45%,
        #fbf1e1 87.79%
    );
    --gradient_glassy: linear-gradient(133.64deg, rgba(255, 255, 255, 0.6) 16.32%, rgba(255, 255, 255, 0.3) 84.41%);
    --gradient_eleven: linear-gradient(270.03deg, rgba(255, 186, 211, 0.25) 2.03%, rgba(186, 134, 253, 0.25) 97.04%);
    --gradient_twelve: linear-gradient(107.91deg, rgba(234, 171, 240, 0.08) 7.37%, rgba(70, 35, 233, 0.08) 95.19%);
    --gradient_glassy_light: linear-gradient(134deg, #ffffff99 16.32%, #fffffff2 84.41%);
    --gradient_thirteen: linear-gradient(to right, var(--bg_primary2), var(--bg_primary2)),
        linear-gradient(to right, #8b37f7, #c754bc, #fb7870, #fda154);
    --reward_gradient_pink: linear-gradient(300.39deg, #fd58b3 0%, #fd66b9 0%, #fb6fb8 0.01%, #ec3694 73.48%);
    --reward_gradient_purple: linear-gradient(304.07deg, #8b37f7 26.39%, #7d27ec 74.53%);
    --reward_gradient_blue_purple: linear-gradient(70.43deg, #3461ff 13.11%, #8454eb 86.43%);

    /* ---------------- Shadow effect ------------------ */
    --view_shadow_regular_01: 0px 10px 60px rgba(226, 236, 249, 0.5);
    --view_shadow_regular_02: 0px 0px 32px rgba(0, 0, 0, 0.04);
    --view_shadow_regular_03: 0px 0px 40px rgba(0, 0, 0, 0.04);
    --view_shadow_Glassy: 0px 10px 28px rgba(0, 0, 0, 0.1);
    --view_shadow_popup_window: 0px 0px 32px rgba(0, 0, 0, 0.04);
    --view_shadow_blur_effect: #ffffff; // need to add filter -----------------
    --view_shadow_regular_dark_01: 0px 2px 8px rgba(52, 97, 255, 0.2);
    --view_shadow_regular_dark_02: 0px 2px 8px rgba(52, 97, 255, 0.5);
    --view_shadow_regular_04: 0px 20px 27px rgba(0, 0, 0, 0.05);
    --tr-shadow: 0 0 9px 1px #3461ff33;

    --overlay_opposite: #00000040; // HTML team added
    --overlay: #000000;
    --bg_popup: #ffffff;

    //Filter
    --filter-primary: none; // two times use
    --filter-secondary: none; // two times use
    --filter-three: none; //single use
    --gray-img: grayscale(1); //landingpage-single use
    --gray-img-hover: grayscale(0); //landingpage-single use

    //slider after before shadow
    --slide-gradient-prev: linear-gradient(91deg, #f2f5fa, #00000000);
    --slide-gradient-next: linear-gradient(91deg, #00000000, #f2f5fa);

    --bg-gradient-color-two: linear-gradient(to right, #f6efff, #f6efff),
        linear-gradient(to right, #373ecc, #7441d9, #aa44e4); // single use
    --bg-gradient-six: linear-gradient(
        243.3deg,
        rgba(225, 202, 255, 0.8) -0.47%,
        rgba(251, 241, 225, 0.8) 94.39%
    ); // single use
    --bg-gradient-seven: linear-gradient(45deg, #ffffff, #eee4fd);

    // added only for rewards steps page
}

body.darkTheme {
    --text_primary: #ffffff;
    --text_secondary: #efefef;
    --text_third: #efefef;
    // --text_fourth: #ffffff;
    --text_fifth: #adadad;

    --sub_text_primary: #999999;
    --sub_text_primary2: #979da0;
    --sub_text_secondary: #e4e4e4;
    --sub_text_secondary2: #efefef;
    --sub_text_secondary3: #8f8f8f;
    --text-purple-reverse: #ffffff;
    --purple_2_reverse: #2f2e39;

    --gray_divider1: #525355;
    --gray_divider2: #30393f;
    --gray_divider3: #383838;
    --gray_divider4: #2e2e2e;
    --gray_divider5: #4c4c4c;

    // --black: #090909;
    --primary_bg_100: #090909;
    --primary_bg_60: rgba(9, 9, 9, 0.6);
    --primary_bg_30: rgba(9, 9, 9, 0.3);
    --purple_color_light: #24272c;

    --secondary_bg: #1e1e2e;
    --secondary_bg_60: rgba(30, 30, 46, 0.6);
    --secondary_bg_30: rgba(30, 30, 46, 0.3);

    --third_bg: #222223;
    --third_bg_60: rgba(34, 34, 35, 0.6);
    --third_bg_30: rgba(34, 34, 35, 0.3);

    --fourth_bg: #29292b;
    --fourth_bg_60: rgba(41, 41, 43, 0.6);
    --fourth_bg_30: rgba(41, 41, 43, 0.3);

    --fifth_bg: #434242;

    --bg_primary: #090909;
    --bg_primary2: #090909;
    --bg_primary3: rgba(22, 22, 22, 0.6);
    --bg_primary4: rgba(41, 41, 43, 0.6);
    --bg_primary5: #1a1a1a;
    --bg_skeleton: rgb(41 41 43);
    --bg_main_card_body: #29292b;

    --bg_secondary: rgba(41, 41, 43, 0.4);
    --bg_secondary2: #222222;
    --bg_secondary3: rgba(217, 200, 255, 0.15);
    --bg_secondary4: #24272c;
    --bg_secondary5: #ffffff;
    --bg-transparent: transparent;

    //--card-gray-transparent: rgba(255, 255, 255, 0.1);
    --bg_gray_primary: #3f3e3e;

    --gradient_ten: linear-gradient(270.03deg, rgba(255, 186, 211, 0.25) 2.03%, rgba(186, 134, 253, 0.25) 97.04%);
    --gradient_eight_10: linear-gradient(
        185.63deg,
        rgba(113, 54, 205, 0.05) -17.15%,
        rgba(253, 243, 242, 0.05) 0.73%,
        rgba(218, 47, 111, 0.05) 39.89%,
        rgba(40, 81, 166, 0.05) 91.46%,
        rgba(253, 243, 242, 0.05) 91.46%,
        rgba(253, 243, 242, 0.05) 91.46%,
        rgba(172, 125, 48, 0.05) 91.46%
    );
    --gradient_twelve: linear-gradient(107.91deg, rgba(234, 171, 240, 0.08) 7.37%, rgba(70, 35, 233, 0.08) 95.19%);

    // Twitter farming
    --gradient_glassy_light: linear-gradient(134deg, #00000078 16.32%, #0000009c 84.41%);

    /* ---------------- Shadow effect ------------------ */
    --view_shadow_regular_01: rgba(53, 55, 56, 0.5);
    --view_shadow_regular_02: rgba(0, 0, 0, 0.04);
    --view_shadow_regular_03: rgba(76, 80, 82, 0.3);
    --view_shadow_Glassy: rgba(0, 0, 0, 0.1);
    --view_shadow_popup_window: rgba(0, 0, 0, 0.04);
    --view_shadow_blur_effect: #171717; // need to add filter
    --view_shadow_regular_dark_01: 0px 5px 10px rgba(0, 0, 0, 0.02);
    --view_shadow_regular_dark_02: 0px 2px 8px rgba(52, 97, 255, 0.5);
    --view_shadow_regular_04: 0px 20px 27px rgba(0, 0, 0, 0.05);
    --shadow: none;

    --overlay_opposite: #ffffff40; // HTML team added
    --overlay: #000000;
    --bg_popup: #29292b;

    //Filter
    --filter-primary: brightness(0);
    --filter-secondary: brightness(1) invert(1);
    --filter-three: invert(0%) sepia(0%) saturate(7403%) hue-rotate(235deg) brightness(-64%) contrast(75%);
    --gray-img: grayscale(0);
    --gray-img-hover: grayscale(0);

    //slider after before shadow
    --slide-gradient-prev: linear-gradient(91deg, #000000, #00000000);
    --slide-gradient-next: linear-gradient(91deg, #00000000, #000000);

    --bg-gradient-color-two: linear-gradient(to right, #070707, #2a2b2c),
        linear-gradient(to right, #373ecc, #7441d9, #aa44e4);
    --bg-gradient-seven: linear-gradient(45deg, #292929, #191b21);
    --bg-gradient-six: #1a1a1a;

    // added only for rewards steps page
}
